import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  Container,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  FormLabel
} from "@mui/material";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
// import { api, useCreateApplicantMutation, useSingleApplicantQuery, useUploadDocMutation } from "../../services/api";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  api,
  useCreateApplicantMutation,
  useCreateRecognitionMutation,
  useGetBETQuery,
  useGetBlockQuery,
  useGetDICListQuery,
  useGetDistrictQuery,
  useGetLegislativeQuery,
  useGetPCQuery,
  useGetStateQuery,
  useGetTehsilQuery,
  // DIC SWCA QUERY required 
} from "../../services/api";
import * as Yup from "yup";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
const schema = Yup.object().shape({
  bet: Yup.string().required("Business Entity Type is required"),
  lac: Yup.string().required("Legislative Assembly Constituency is required"),
  parliment: Yup.string().required('Parliament  is required'),
  // constituency:Yup.string().required('Contituency is required'),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  dicSwca: Yup.string().required("DIC is required"),
  tehsil: Yup.string().required("Tehsil is required"),
  block: Yup.string().required("Block is required"),
  pin: Yup.string().required("PIN is required"),
  gst_no: Yup.string().required("Gst No is Required"),
  company_name:Yup.string().required("Company name is Required"),
  incorporation_number: Yup.string().required(
    "Incorporation Number is required"
  ),
  udyam_no: Yup.string().required("Udyam Number is required"),
  business_address: Yup.string()
    .nullable()
    .max(255, "Business Address should be at most 255 characters long")
    .required("Business Address is required"),
});

const BusinessDetailsDuplicate = ({ steps, active, setActive }) => {
  // const [createApplicant] = useCreateApplicantMutation();
  // const [fileUpload] = useUploadDocMutation();
  const _id = localStorage.getItem("recognition_id");
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
 const {data:getDicList}= useGetDICListQuery()
  // const {
  //   data: getApplicant,

  // } = useSingleApplicantQuery(_id);
 

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([
    {
      document: "Proposal For Presentation",
      type: "Pdf/Word/Ppt",
      name: "",
      file: null,
    },
  ]);
  const { data: betList } = useGetBETQuery();
  const { data: lacList, isLoading, isError } = useGetLegislativeQuery();
  const { data: pcList } = useGetPCQuery();
  const { data: stateList } = useGetStateQuery(); // Get Incubation list
  const { data: districtList } = useGetDistrictQuery();
  const { data: tehsilList } = useGetTehsilQuery();
  const { data: blockList } = useGetBlockQuery();
  const [recognitionCreate] = useCreateRecognitionMutation();
  const [getSingleRecognition] = api.endpoints.singleRecognition.useLazyQuery();
  const [lacData] = api.endpoints.getLegislativeDistrictWise.useLazyQuery();
  const [contiTencyData] = api.endpoints.getParlimentContiWise.useLazyQuery();
  const [tehsilDataDistrictWise] = api.endpoints.getTehsilDistrictWise.useLazyQuery();

  const formik = useFormik({
    initialValues: {
      bet: "",
      lac: "",
      parliment: "",
      constituency:"",
      lacList:[],
      constiList:[],
      blockList:[],
      tehsilList:[],
      state: "",
      district: "",
      dicSwca: '',
      tehsil: "",
      block: "",
      pin: "",
      gst_no: "",
      incorporation_number: "",
      udyam_no: "",
      business_address: "",
      gst_no_doc:"",
      company_name:"",
      date_of_company:new Date(),

    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        let data = {
          step: "business",
          payload: {
            business_entity_type: values.bet,
            business_address: values.business_address,
            business_lac: values.lac,
            // business_parliament_constituency: values.pc,
            business_parliament: values?.parliment,
            business_constituency: values?.constituency,
            business_state: values.state,
            business_district: values.district,
            business_dic: values.dicSwca,
            business_teshil: values.tehsil,
            business_block: values.block,
            business_pin: values.pin,
            gst_no: values.gst_no,
            incorporation_number: values.incorporation_number,
            udyam_no: values.udyam_no,
            business_company_name:values.company_name,
            business_company_date:values.date_of_company,
            application_id: _id,
          },
        };
        const response = await recognitionCreate(data);
        if (response?.data?.success) {
          toast.success("Business Details Created Sucessfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            setActive((prevActive) => prevActive + 1);
          }, 3000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    },
  });
  
  const handleBack = () => {
    setActive((prevActive) => prevActive - 1);
  };
  const handleNext = (e) => {
    e.preventDefault()
    formik.handleSubmit();
  };
  const handleDistrictChange = async (id) => {
    formik.setFieldValue("district", id);
    const respose = await lacData(id);
    const res = await tehsilDataDistrictWise(id);
    if (respose?.data?.success) {
      formik.setFieldValue("lacList", respose?.data?.data);
    }
    if(res?.data?.success) {
      formik.setFieldValue("tehsilList", res.data?.data?.tehsil);
      formik.setFieldValue("blockList", res?.data?.data?.block);

    }
  };
  const handleParChange = async (id) => {
    formik.setFieldValue("parliment", id);
    try {
      const response = await contiTencyData(id);
      if (response?.data?.success) {
        formik.setFieldValue("constiList", response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching constituencies:", error);
    }
  };

  
  const fileInputRef = useRef(null);

  const handleIconClick = (index) => {
    fileInputRef.current && fileInputRef.current.click();
  };
  React.useEffect(() => {
    const UpdateRecognition = async () => {
      if (_id) {
        try {
          const { data } = await getSingleRecognition(_id);
          const {
            business_entity_type,
            business_address,
            business_lac,
            business_parliament,
            business_constituency,
            business_state,
            business_district,
            business_dic,
            business_teshil,
            business_block,
            business_pin,
            gst_no,
            incorporation_number,
            udyam_no,
            business_company_date,
            business_company_name
            
          } = data?.data;
           

          formik.setValues((prev) => ({
            ...prev,
            bet: business_entity_type,
            lac: business_lac,
            // pc: business_parliament_constituency,
            parliment: business_parliament,
            constituency:business_constituency,
            state: business_state,
            district: business_district,
            dicSwca: business_dic,
            tehsil: business_teshil,
            block: business_block,
            pin: business_pin,
            gst_no,
            incorporation_number,
            udyam_no,
            business_address: business_address,
            company_name:business_company_name,
            date_of_company:business_company_date
          }));
          if (business_district) {
            await handleDistrictChange(business_district);
          }
          if (business_parliament) {
           await handleParChange(business_parliament);
          }
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateRecognition();
  }, [_id]);

  return (
    <>
      <ToastContainer />
      <Typography
        variant="h6"
        align="left"
        sx={{ fontWeight: 600, color: "#2074af" }}
      >
        Business Details
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Business Entity Type :
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="bet">Business Entity Type</InputLabel>
              <Select
                margin="dense"
                required
                fullWidth
                id="bet"
                label="Select Priority Sector"
                placeholder="Select Priority Sector"
                name="bet"
                // autoFocus
                value={formik.values.bet}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.bet && Boolean(formik.errors.bet)}
                // helperText={formik.touched.bet && formik.errors.bet}
                autoComplete="Register type"
              // onChange={(e) => handleChange(e)}
              >
                {betList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              District:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">District</InputLabel>
              <Select
                margin="dense"
                required
                fullWidth
                id="district"
                label="District"
                placeholder="District"
                name="district"
                // autoFocus
                value={formik.values.district}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.district && Boolean(formik.errors.district)
                }
                helperText={formik.touched.district && formik.errors.district}
                autoComplete="Register type"
              // onChange={(e) => handleChange(e)}
              >
                {districtList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item md={6} lg={6} xl={6} xs={12} mt={2}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              DIC/ SWCA:
            </Typography>
            <FormControl fullWidth error={formik.touched.dicSwca && Boolean(formik.errors.dicSwca)}>
              <InputLabel id="dicSwca">DIC/ SWCA</InputLabel>
              <Select
                label="DIC/ SWCA"
                id="dicSwca"
                name="dicSwca"
                placeholder="DIC/ SWCA"
                value={formik.values.dicSwca}
                onChange={formik.handleChange}
              >
                  {getDicList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.dicSwca && formik.errors.dicSwca ? (
                <FormHelperText>{formik.errors.dicSwca}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>



          {/* Inspiration Behind Idea */}
          <Grid item md={6} lg={6} xl={6} xs={12} >
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Company Name :
            </Typography>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="company_name"
                label="Company Name"
                name="company_name"
                autoComplete="Inspiration Behind Idea"
                value={formik.values.company_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.company_name &&
                  Boolean(formik.errors.company_name)
                }
                helperText={
                  formik.touched.company_name &&
                  formik.errors.company_name
                }
              />
            </FormControl>
          </Grid>
          {/* <Grid item md={12} lg={12} xl={12} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Date of Company:
            </Typography>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="date_of_company"
                label="Business Address"
                name="date_of_company"
                autoComplete="Inspiration Behind Idea"
                type="textarea"
                rows={2}
                multiline
                value={formik.values.date_of_company}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.date_of_company &&
                  Boolean(formik.errors.date_of_company)
                }
                helperText={
                  formik.touched.date_of_company &&
                  formik.errors.date_of_company
                }
              />
            </FormControl>
          </Grid> */}
          <Grid item md={6} lg={6} xl={6} xs={12} >
          <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Date of Company :
            </Typography>
                      <DatePicker
                        disableFuture
                        sx={{
                          width: "100%",
                          marginTop: "1em",
                          marginBottom: "0.5em",
                        }}
                        value={dayjs(moment(formik.values.date_of_company).format("YYYY-MM-DD"))}
                        selected={formik.values.dpiit_start_date}
                        name="company_start_date"
                        label="Company Date"
                        margin="normal"
                        onChange={(value) =>
                          formik.setFieldValue("date_of_company", value["$d"])
                        }
                      />
                    </Grid>
          <Grid item md={12} lg={12} xl={12} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Business Address (Registered Office):
            </Typography>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="business_address"
                label="Business Address"
                name="business_address"
                autoComplete="Inspiration Behind Idea"
                type="textarea"
                rows={2}
                multiline
                value={formik.values.business_address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_address &&
                  Boolean(formik.errors.business_address)
                }
                helperText={
                  formik.touched.business_address &&
                  formik.errors.business_address
                }
              />
            </FormControl>
          </Grid>
          {/* <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Tehsil:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Tehsil</InputLabel>
              <Select
                margin="dense"
                required
                fullWidth
                id="tehsil"
                label="Parliament Constituency"
                placeholder="Parliament Constituency"
                name="tehsil"
                // autoFocus
                value={formik.values.tehsil}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.tehsil && Boolean(formik.errors.tehsil)}
                helperText={formik.touched.tehsil && formik.errors.tehsil}
                autoComplete="Register type"
              // onChange={(e) => handleChange(e)}
              >
                {tehsilList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Block:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Block</InputLabel>
              <Select
                margin="dense"
                required
                fullWidth
                id="block"
                label="Parliament Constituency"
                placeholder="Parliament Constituency"
                name="block"
                // autoFocus
                value={formik.values.block}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.block && Boolean(formik.errors.block)}
                helperText={formik.touched.block && formik.errors.block}
                autoComplete="Register type"
              // onChange={(e) => handleChange(e)}
              >
                {blockList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              State/ UT:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">State/ UT</InputLabel>
              <Select
                margin="dense"
                required
                fullWidth
                id="state"
                label="State/ UT"
                placeholder="State"
                name="state"
                // autoFocus
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.state && Boolean(formik.errors.state)}
                // helperText={formik.touched.state && formik.errors.state}
                autoComplete="Register type"
              // onChange={(e) => handleChange(e)}
              >
                {stateList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography variant="h6" align="left" sx={{ fontWeight: 400, fontSize: "18px" }}>
              District:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="district">District</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="district"
                label="District"
                name="district"
                value={formik.values.district}
                onChange={(e) => handleDistrictChange(e.target.value)}
                onBlur={formik.handleBlur}
                error={formik.touched.district && Boolean(formik.errors.district)}
                // helperText={formik.touched.district && formik.errors.district}
              >
                {districtList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Legislative Assembly Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="lac">
                Legislative Assembly Constituency
              </InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="lac"
                label="Select Priority Sector"
                placeholder="Select Priority Sector"
                name="lac"
                // autoFocus
                value={formik.values.lac}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lac && Boolean(formik.errors.lac)}
                // helperText={formik.touched.lac && formik.errors.lac}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {formik?.values?.lacList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Parliament Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="parliment">Parliament </InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="parliment"
                label="Parliament"
                placeholder="Parliament"
                name="parliment"
                // autoFocus
                value={formik.values.parliment}
                onChange={(e) => {
                  e.preventDefault();
                  handleParChange(e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.parliment && Boolean(formik.errors.parliment)}
                // helperText={formik.touched.parliment && formik.errors.parliment}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
                
              >
                {pcList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Tehsil:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Tehsil</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="tehsil"
                label="tehsil"
                placeholder="Tehsil"
                name="tehsil"
                // autoFocus
                value={formik.values.tehsil}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.tehsil && Boolean(formik.errors.tehsil)}
                // helperText={formik.touched.tehsil && formik.errors.tehsil}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                 {formik?.values?.tehsilList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Block:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Block</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="block"
                label="block"
                placeholder="Block"
                name="block"
                // autoFocus
                value={formik.values.block}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.block && Boolean(formik.errors.block)}
                // helperText={formik.touched.block && formik.errors.block}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                 {formik?.values?.blockList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Parliament:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="parliment">Parliament </InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="parliment"
                label="Parliament"
                placeholder="Parliament"
                name="parliment"
                // autoFocus
                value={formik.values.parliment}
                onChange={(e) => {
                  handleParChange(e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.parliment && Boolean(formik.errors.parliment)}
                // helperText={formik.touched.parliment && formik.errors.parliment}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
                
              >
                {pcList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography variant="h6" align="left" sx={{ fontWeight: 400, fontSize: "18px" }}>
              Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="constituency">Constituency</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="constituency"
                label="Constituency"
                name="constituency"
                value={formik.values.constituency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.constituency && Boolean(formik.errors.constituency)}
                helperText={formik.touched.constituency && formik.errors.constituency}
              >
                {formik.values.constiList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          
          
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              PIN:
            </Typography>
            <TextField
              margin="dense"
              fullWidth
              required
              id={`pin`}
              label="PIN"
              name={`pin`}
              placeholder="PIN"
              autoComplete={"off"}
              value={formik.values?.pin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.pin && Boolean(formik.errors.pin)}
              helperText={formik.touched.pin && formik.errors.pin}
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              GST Number:
            </Typography>
            <TextField
              margin="dense"
              fullWidth
              required
              id={`gst_no`}
              label="GST Number"
              name={`gst_no`}
              placeholder="GST Number"
              autoComplete={"off"}
              value={formik.values?.gst_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.gst_no && Boolean(formik.errors.gst_no)}
              helperText={formik.touched.gst_no && formik.errors.gst_no}
            />
          </Grid>
          {/* <Grid item xl={2} lg={2} md={2} sm={12} xs={12} mt={5}>
                <FormControl className="file-upload-style">
                   
                    <CloudUploadIcon 
                    className="icon-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => fileInputRef.current.click()}
                    />
                    { formik?.values?.gst_no_doc ? (
                      <Link
                        to={URL.createObjectURL(formik?.values?.gst_no_doc )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <VisibilityIcon />
                      </Link>
                    ) : (
                      <></>
                    )}
                    <Typography color="black" fontSize={14}>
                      {formik.values.gst_no_doc.name}
                    </Typography>
                    <FormLabel>GST NO.</FormLabel>
                    <input
                      type="file"
                      accept=".pdf"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={(event) =>{

                        formik.setFieldValue("gst_no_doc", event.target.files[0]);
                      }
                      }
                    />
                    {formik.errors.gst_no_doc &&
                      formik.touched.gst_no_doc && (
                        <Typography color="error">
                          {formik.errors.gst_no_doc}
                        </Typography>
                      )}
                  </FormControl>
                 
                </Grid> */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Incorporation Number:
            </Typography>
            <TextField
              margin="dense"
              fullWidth
              required
              id={`incorporation_number`}
              label="Incorporation Number"
              name={`incorporation_number`}
              placeholder="Incorporation Number"
              autoComplete={"off"}
              value={formik.values?.incorporation_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.incorporation_number &&
                Boolean(formik.errors.incorporation_number)
              }
              helperText={
                formik.touched.incorporation_number &&
                formik.errors.incorporation_number
              }
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Udyam Number :
            </Typography>
            <TextField
              margin="dense"
              fullWidth
              required
              id={`udyam_no`}
              label="Udyam Number"
              name={`udyam_no`}
              placeholder="Udyam Number"
              autoComplete={"off"}
              value={formik.values?.udyam_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.udyam_no && Boolean(formik.errors.udyam_no)}
              helperText={formik.touched.udyam_no && formik.errors.udyam_no}
            />
          </Grid>
          {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} gap={2} className="button-right">
           <Button
            type="button"
            variant="contained"
            onClick={handleBack}
            disabled={active === 0}
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
          >
            Previous
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleNext}
            disabled={active === steps.length}
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
          >
            <span>Next</span>
          </LoadingButton>
          </Grid> */}
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="button-right"
          >
            <Button
              type="button"
              variant="contained"
              onClick={handleBack}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={(e) => handleNext(e)}
                disabled={active === steps.length - 1}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                type="submit"
              >
                <span>Next</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BusinessDetailsDuplicate;
