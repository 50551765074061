import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Grid,
  useTheme,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import black_Bg from "../../Assests/auth-bg.jpg";
import DownloadIcon from "@mui/icons-material/Download";
import {
  useIncubationDetailsQuery,
  api,
  useGetFilterAgendaListQuery,
  useGetPublicDashboardGrievanceQuery,
} from "../../services/api";
import { useFormik } from "formik";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GrievanceDashboard = () => {
  const [dropdownValue, setDropdownValue] = useState("");
  const {
    data: incubationList,
    isLoading,
    isError,
  } = useIncubationDetailsQuery();
  const theme = useTheme();

  const [filterAgenda, { data: filterAgendaList }] =
    api.endpoints.getFilterAgendaList.useLazyQuery();
  const { data: list } = useGetPublicDashboardGrievanceQuery();
  const { t } = useTranslation();
  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };
  const formik = useFormik({
    initialValues: {
      incubation_id: "",
    },
    validationSchema: null,
  });

  const HandleIncubationChange = (e) => {
    setDropdownValue(e.target.value);
    // filterAgenda({ id: e.target.value });
  };

  return (
    <Box className="screen-app-container">
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: `url(${
            theme.palette.mode === "dark" ? null : black_Bg
          }) center center no-repeat`,
          backgroundSize: "cover",
          width: "100%",
          minHeight: "100vh",
          height: "100%",
          alignItems: "center",
          backgroundColor: (theme) => theme.palette.background.default,
          color: (theme) => theme.palette.text.primary,
        }}
      >
        <Container component="main" className="register-page">
          <Paper
            sx={{
              padding: "3em 2em",
              margin: "1em 0",
            }}
            elevation={5}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "20%",
                flexDirection: "column",
                gap: "10px",
                marginBottom: "2rem",
              }}
            >
              <Grid item md={6} lg={6} sm={6}>
                <Typography variant="h5" align="center" fontWeight="600">
                { t("Real Time Dashboard")}
                </Typography>
              </Grid>
              <Grid item md={6} lg={6} sm={6}>
                <CustomizedProgressBars val={50} />
              </Grid>
            </Grid>
            {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <InputLabel id="language_change" sx={{ color: "#000" }}>
                Incubation Type
              </InputLabel>
              <Select
                fullWidth
                value={formik.values.incubation}
                onChange={(e) => HandleIncubationChange(e)}
                labelId="incubation-label"
                id="incubation"
                name="incubation"
                label="Incubation Type"
                autoComplete="off"
                placeholder="Select Incubation"
              >
                {!isLoading &&
                  incubationList?.data?.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item.host_institute_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid> */}
            <TableContainer component={Paper} >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Total Queries</b>
                    </TableCell>
                    <TableCell>
                      <b>Total Resolved Queries</b>
                    </TableCell>
                    <TableCell>
                      <b>Total Pending Queries</b>
                    </TableCell>
                    <TableCell>
                      <b>Min Time Taken to resolve query (in days)</b>
                    </TableCell>
                    <TableCell>
                      <b>Max Time Taken to resolve query (in days)</b>
                    </TableCell>
                    <TableCell>
                      <b>Average Time Taken to resolve query (in days)</b>
                    </TableCell>
                    <TableCell>
                      <b>Median Time Taken to resolve query (in days)</b>
                    </TableCell>
                    <TableCell>
                      <b>Timeline as per Government Notification</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{list?.data?.total}</TableCell>
                    <TableCell>{list?.data?.closed}</TableCell>
                    <TableCell>{list?.data?.pending}</TableCell>

                    <TableCell>{list?.data?.min_time}</TableCell>
                    <TableCell>{list?.data?.max_time}</TableCell>
                    <TableCell>{list?.data?.average_time}</TableCell>
                    <TableCell>{list?.data?.median_time}</TableCell>
                    <TableCell>{/* {list?.data?.pending} */}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default GrievanceDashboard;
