import React from "react";
import { Box, Typography, Container, useTheme } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import stats_bg from "../../Assests/stats-bg.jpg";
import { data } from "./constant";
import { useTranslation } from "react-i18next";
import { useGetTotalGrievanceDataQuery } from "../../services/api";
import { ThemeContext } from "../../services/ThemeContext";
function Details() {
  const {data:grievanceData}=useGetTotalGrievanceDataQuery()
  const theme=useTheme()
  const { fontSize } = React.useContext(ThemeContext);
  // const thumbnailOptions = {
  // start  : 1,
  // perPage: 5,
  // gap:40,
  // rewind : true,
  // autoHeight: true,
  // rewindSpeed: 1000,
  // autoHeight: true,
  // autoplay: true,
  // backgroundColor: (theme) => theme.palette.background.default,
  // color: (theme) => theme.palette.text.primary,
  // };
  const thumbnailOptions = {
    start  : 1,
    perPage: 4,
    gap:20,
    rewind : true,
    autoHeight: true,
    rewindSpeed: 1000,
    autoHeight: true,
    autoplay: true,
    breakpoints: {
			640: {
				perPage: 1,
			},
      768: {
        perPage: 2, // 2 slides per page on screens between 641px and 768px
      },
      1024: {
        perPage: 3, // 3 slides per page on screens between 769px and 1024px
      },
      1280: {
        perPage: 4, // 4 slides per page on screens between 1025px and 1280px
      },
		},
    
  };

  const { t } = useTranslation();
 

  return (
    <Box className="App_">
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundImage: `url(${theme.palette.mode==="dark"?null:stats_bg})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          padding: "70px 0", // Equal padding
          justifyContent: "space-around",
          backgroundColor:  theme.palette.background.default,
          color:  theme.palette.text.primary,
        }}
      >
        <Container
          maxWidth="xl"
          
          
        >
          <Splide options={thumbnailOptions} >
            {Array.isArray( grievanceData?.data) &&
              grievanceData?.data?.map((thumbnail, index) => {
                return (
                  <SplideSlide
                    className="custom-splide"
                    key={index}    
                    style={{backgroundColor: theme.palette.background.default,
                      color:  theme.palette.text.primary}}               
                  >
                    <Box className="splide-box" sx={{ backgroundColor: (theme) => theme.palette.background.default,
                          color: (theme) => theme.palette.text.primary}}>
                      <img
                        className="splide-ic"
                        src={thumbnail?.url}
                        alt={`Thumbnail ${index + 1}`}
                        
                      />
                      <Box className="splide-custom-text">
                        <Typography
                          sx={{
                            fontWeight: 800,
                            fontSize: `${fontSize}px`,
                            marginTop: 3,
                            textAlign: "center",
                            whiteSpace: "nowrap",
                                  backgroundColor: (theme) => theme.palette.background.default,
                                  // color: (theme) => theme.palette.text.primary,
                          }}
                          component="h3"
                        >
                          {thumbnail?.name}
                        </Typography>
                        <Typography
                          textAlign={"center"}
                          sx={{
                            objectFit: "cover",
                            textAlign: "center",
                            overflow: "hidden",
                            backgroundColor: (theme) => theme.palette.background.default,
                            fontSize: `${fontSize}px`,
                                    // color: (theme) => theme.palette.text.primary,
                          }}
                        >
                          {t(thumbnail?.title)}
                        </Typography>
                      </Box>
                    </Box>
                  </SplideSlide>
                );
              })}
          </Splide>
        </Container>
      </Box>
      {/* </Box> */}
    </Box>
  );
}

export default Details;
