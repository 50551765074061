import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid, Box, Container } from "@mui/material";
import CustomizedProgressBars from "./../CustomProgress/ProgressBar";
import { useTranslation } from "react-i18next";
import videoImg from "../../Assests/startup.png";
import "./style.scss";
import { ThemeContext } from "../../services/ThemeContext";

const IframeSection = () => {
  const { t, i18n } = useTranslation();
  const {fontSize } = React.useContext(ThemeContext);
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        padding: "30px 0", // Equal padding
        justifyContent: "space-around",
        // backgroundColor: "#ffffff",
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ padding: 2 }}>
              {/* <Typography
                component="h1"
                sx={{ fontWeight: 700, fontSize: "x-large" }}
              >
                {t("start_up_title")}
              </Typography>
              <CustomizedProgressBars val={50} /> */}
              <Grid textAlign={"center"} sx={{ marginBottom: 3 }} item xs={12}>
            <Typography
              component="h1"
              ml={2}
              sx={{ fontWeight: 700, fontSize: `${fontSize}px`, color: (theme) => theme.palette.text.primary }}
            >
              {t("start_up_title")}
            </Typography>
            <CustomizedProgressBars className={"section_sep"} val={50} />
          </Grid>
              <Typography className="subheading-blue" component="h2" sx={{ fontWeight:500, fontSize: `${fontSize}px`,}}>
               { t("iframe_title")}
              </Typography>
              <Typography className="para-margin" variant="p" component="p" sx={{  fontSize: `${fontSize}px`,}}>
               {t('iframe_typo1')}
              </Typography>
              <Typography className="para-margin" variant="p" component="p" sx={{ fontSize: `${fontSize}px`,}}>
                {t('iframe_typo2')}
              </Typography>
              <Typography className="para-margin" variant="p" component="p" sx={{ fontSize: `${fontSize}px`,}}>
                {t("iframe_typo3")}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Box component="img" src={videoImg} sx={{ maxWidth: "100%" }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default IframeSection;
