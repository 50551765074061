import * as React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  useCreateBlockMutation,
  useGetDistrictQuery,
  useUpdateBlockMutation,
} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { setBlockData } from "../../Redux/GlobalSlice";

export default function UpsertBlock({ state, toggleDrawer, showCase }) {
  const [createBlock] = useCreateBlockMutation();
  const [updateBlock] = useUpdateBlockMutation(); // Get Incubation list
  const blockData = useSelector((state) => {
    return state?.data?.data?.blockData;
  });
  const { data: districtList, isLoading, isError } = useGetDistrictQuery();

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    district: yup.string().required("State is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      district: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const { data } = await createBlock({
          name: values.name,
          district_id: values.district,
        });
        if (data?.success) {
          toast.success("State list is Created successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          formik.setFieldValue("name", "");
          toggleDrawer("right", false)(values);
        }
      } catch (error) {
        console.log("error=>", error);
      }
    },
  });
  const dispatch = useDispatch();
  const handleUpsert = async (e) => {
    if (showCase === "edit") {
      if (formik.isValid) {
        const data = await updateBlock({
          id: blockData?._id,
          payload: { name: formik?.values?.name },
        });
        if (data?.data?.success) {
          toast.success("State list is Updated successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          formik.setFieldValue("name", "");
          toggleDrawer("right", false)(e);
        }
      }
    } else {
      formik.handleSubmit();
    }
  };

  React.useEffect(() => {
    if (showCase === "edit") {
      formik.setFieldValue("name", blockData?.name);
      formik.setFieldValue("district", blockData?.district_id?._id);
    }
  }, [blockData?.district_id, state["right"]]);
  const handleClose = (event) => {
    formik.setValues((prev) => ({
      ...prev,
      name: "",
      district: "",
    }));
    // dispatch(setBlockData({}));
    toggleDrawer("right", false)(event);
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={(event) => {
          toggleDrawer("right", false)(event);
          formik.resetForm();
        }}
      >
        {/* Content inside the Drawer goes here */}
        <Box role="presentation">
          <Box width={"95%"} marginLeft={"auto"} marginRight={"auto"} mb={2}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
            >
              {`${showCase === "edit" ? "Update" : "Add"} Block`}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontWeight: 400 }}
                  >
                    Name:
                  </Typography>
                  <FormControl fullWidth>
                    <TextField
                      sx={{ width: "100%" }}
                      margin="dense"
                      required
                      fullWidth
                      id="Legislative-name"
                      label="Name"
                      name="name"
                      autoComplete="off"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      //   autoFocus={false}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="state">Select District</InputLabel>
                    <Select
                      margin="dense"
                      required
                      fullWidth
                      id="district"
                      label="Select District"
                      name="district"
                      autoFocus
                      value={formik.values.district}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.district &&
                        Boolean(formik.errors.district)
                      }
                      autoComplete="off"
                    >
                      {districtList?.data?.map((item) => (
                        <MenuItem value={item._id} key={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", marginTop: "95%" }}>
                <Button
                  // type="submit"
                  onClick={(e) => {
                    handleUpsert(e);
                  }}
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 2,
                    borderRadius: 10,
                  }}
                >
                  {showCase !== "edit" ? "Save" : "Update"}
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    mt: 3,
                    mb: 2,
                    borderRadius: 10,
                  }}
                  onClick={(event) => {
                    toggleDrawer("right", false)(event);
                    formik.setFieldValue("name", "");
                  }}
                >
                  Cancel
                </Button>
              </Grid>

              {/* Add your submit button here */}
            </Box>
            {/* </Paper> */}
          </Box>
        </Box>
        <IconButton
          onClick={(event) => {
            handleClose(event);
          }}
          style={{ position: "absolute", top: "8px", right: "8px" }}
        >
          <CloseIcon />
        </IconButton>
      </Drawer>
    </React.Fragment>
  );
}
