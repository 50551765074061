//New updated code  using material ui
import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
  Box,
  Container,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setImage } from "../../Redux/GlobalSlice";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import { AlertTitle } from "@mui/material";
import { data } from "./constant";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../services/ThemeContext";

export default function Cubators() {
  const [id, setId] = React.useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { fontSize } = React.useContext(ThemeContext);
  const handleImageClick = (item) => {
    setId(item?.id);
    dispatch(setImage(item));
  };
  const thumbnailOptions = {
    start  : 1,
    perPage: 6,
    gap:20,
    rewind : true,
    autoHeight: true,
    rewindSpeed: 1000,
    autoHeight: true,
    autoplay: true,
    breakpoints: {
			640: {
				perPage: 1,
			},
      768: {
        perPage: 3, // 2 slides per page on screens between 641px and 768px
      },
      1024: {
        perPage: 4, // 3 slides per page on screens between 769px and 1024px
      },
      1280: {
        perPage: 5, // 4 slides per page on screens between 1025px and 1280px
      },
		},
    
  };

  return (
    <Box
      sx={{
        background: "#ffffff",
        flexDirection: "row",
        padding: "50px 0", // Equal padding
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
      }}
    >
      <Container
        maxWidth="xl"
        // style={{
        //   height: "100%",
        //   width: "100%",
        //   margin: "20px", // Equal margin
        //   padding: "20px", // Equal padding
        //   justifyContent: "center",
        //   marginLeft: "50px",
        // }}
        spacing={10}
      >

        <Grid textAlign={"center"} item xs={12} sx={{ marginBottom: 3 }}>
          
          <Typography
            component="h1"
            sx={{ fontWeight: 700, fontSize: `${fontSize}px` }}
          >
            {t("Incubators")}
          </Typography>
          <CustomizedProgressBars className={"section_sep"} val={50} />
        </Grid>

        {/* <ImageList
          style={{
            width: "100%",
            display: "flex",
            gap: 10,
            borderRadius: "10px",
          }}
          cols={3}
          rowHeight={"auto"}
        > */}
          <Splide options={thumbnailOptions}>
            {data.map((item, index) => {
              return (
                <SplideSlide className={"splideslide-align"}
                  key={item?.id}
                  style={{
                    borderRadius: 10,
                  }}
                >
                  <Box
                    key={item?.id}
                    onClick={() => {
                      handleImageClick(item);
                    }}
                  >
                    <img
                      style={{
                        objectFit: "contain",
                        width: "145px",
                        height: "145px",
                        display: "inline-block",
                      }}
                      srcSet={`${item?.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      src={`${item?.img}?w=164&h=164&fit=crop&auto=format`}
                      alt={item?.title}
                      loading="lazy"
                    />
                  </Box>
                </SplideSlide>
              );
            })}
          </Splide>
        {/* </ImageList> */}
      </Container>
    </Box>
  );
}
