import Eligibility from "../../Assests/Check Your  Eligibility.png";
import Policy from "../../Assests/Startup Policy  Notifications.png";
import Network from "../../Assests/netwok.png";
import Icubator from "../../Assests/Explore existing  Incubator network.png";
import Icubator1 from "../../Assests/success-story-2@2x.png";
import Icubator2 from "../../Assests/success-story-1@2x.png";
import Icubator3 from "../../Assests/success-story-4@2x.png";
const currentDate = new Date();
const options = { day: "numeric", month: "long", year: "numeric" };
const formattedDate = currentDate.toLocaleDateString("en-US", options);
export  const data = [
    {
      id: 1,
      subject: "Skill india: education to employement bridge",
      date:formattedDate,
      content:"Skills and knowledge serve as pivotal drivers of economic growth and societal",
      media:Icubator2 ,
    },
    {
      id: 2,
      subject: "Ensuring resposible and thrustworthy ai for all",
      date:formattedDate,
      content:"An AI system is a machine-based system that, for explicit or implicit objectives, infers",
      media: Icubator1,
    },
    {
      id: 3,
      subject: "PM Vishwakarma scheme: empowering artisans",
      date:formattedDate,
      content:"India's vibrant handicraft sector weaves rich tapestry of economic, cutural, and social",
      media: Icubator3,
    },
    // {
    //   id: 4,
    //   subject: "Opportunities in the indian telecommunication sector",
    //   date:formattedDate,
    //   content:
    //   "india_telecom",
    //   media: Icubator,
    // },
    {
      id: 4,
      subject: "Skill india: education to employement bridge",
      date:formattedDate,
      content:"Skills and knowledge serve as pivotal drivers of economic growth and societal",
      media: Eligibility,
    },
    {
      id: 5,
      subject: "Ensuring resposible and thrustworthy ai for all",
      date:formattedDate,
      content:"An AI system is a machine-based system that, for explicit or implicit objectives, infers",
      media: Policy,
    },
    {
      id: 6,
      subject: "PM Vishwakarma scheme: empowering artisans",
      date:formattedDate,
      content:"India's vibrant handicraft sector weaves rich tapestry of economic, cutural, and social",
      media: Network,
    },
    {
      id: 7,
      subject: "Opportunities in the indian telecommunication sector",
      date:formattedDate,
      content:"India's Telecom sector has witnessed stupendous growth in last few years Today.",
      media: Icubator,
    },
    {
      id: 8,
      subject: "Skill india: education to employement bridge",
      date:formattedDate,
      content:"Skills and knowledge serve as pivotal drivers of economic growth and societal",
      media: Eligibility,
    },
    {
      id: 9,
      subject: "Ensuring resposible and thrustworthy ai for all",
      date:formattedDate,
      content:"An AI system is a machine-based system that, for explicit or implicit objectives, infers",
      media: Policy,
    },
    {
      id: 10,
      subject: "PM Vishwakarma scheme: empowering artisans",
      date:formattedDate,
      content:"India's vibrant handicraft sector weaves rich tapestry of economic, cutural, and social",
      media: Network,
    },
    {
      id: 11,
      subject: "Opportunities in the indian telecommunication sector",
      date:formattedDate,
      content:"India's Telecom sector has witnessed stupendous growth in last few years Today.",
      media: Icubator,
    },
  ];