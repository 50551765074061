import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { api } from '../../services/api';
import { useEffect,useState } from 'react';
import useDrawer from './../IncubationCentre/customiseHook';
import { Grid } from '@mui/material';


export default function StackedBarChart() {
  const [barLogs] = api.endpoints.barChartLogs.useLazyQuery();
  const [pending, setPending] = useState([]);
  const [approved, setApproved] = useState([]);
  const [chartHeight, setChartHeight]=useState(400);
  const [chartwidth, setChartWidth]=useState(null);

  const [name, setName] = useState([]);
  const { state, toggleDrawer } = useDrawer();
  const barLogsFun = async () => {
    try {
      const barData = await barLogs(); // Fetch the data  
      // Ensure barData and its nested properties are defined before destructuring
      if (barData?.data?.data) {
        const { pending, approved, namelist } = barData.data.data;
        setPending(pending);
        setApproved(approved);
        setName(namelist);
      } else {
        console.error("barData structure is not as expected:", barData);
      }
    } catch (error) {
      console.error("Error fetching barLogs:", error);
    }
  };
  
  useEffect(() => {
    barLogsFun();
 if(window.innerWidth>700 && window.innerWidth<800 ){
  setChartWidth(650)
}
 else if(window.innerWidth>800 && window.innerWidth<900 ){
  setChartWidth(700)
}
 else if(window.innerWidth>900 && window.innerWidth<1000 ){
  setChartWidth(850)
}
else if(window.innerWidth>1000 && window.innerWidth<1100 ){
  setChartWidth(950)
}
else if(window.innerWidth>1100 && window.innerWidth<1200){
  setChartWidth(850)
}
else if(window.innerWidth>1200 && window.innerWidth<1300){
  setChartWidth(900)
}
else if(window.innerWidth>1300 && window.innerWidth<1500){
  setChartWidth(1000)
}
else if(window.innerWidth>1500 && window.innerWidth<1600){
  setChartWidth(1100)
}
else if(window.innerWidth===1600 ){
  setChartWidth(1200)
}
else if(window.innerWidth>1600 && window.innerWidth<1700){
  setChartWidth(1200)
}
else if(window.innerWidth>1700 && window.innerWidth<1800){
  setChartWidth(1250)
}
else if(window.innerWidth>1800 && window.innerWidth<1900){
  setChartWidth(1300)
}
else if(window.innerWidth>1900 && window.innerWidth<2000){
  setChartWidth(1350)
}
else{
  setChartWidth(300)
}
}, [window.innerWidth]);


  // return;
  return (
    <Grid md={6} lg={6} xs={12} sm={12}>

      <BarChart
        width={chartwidth}
        height={chartHeight}
        series={[
          { data: pending, label: 'pending', id: 'pId', stack: 'total',color: 'red' },
          { data: approved, label: 'approved', id: 'aId', stack: 'total',color: 'green' },
        ]}
        xAxis={[{ data: name, scaleType: 'band' }]}
      />
    </Grid>
  );
}
