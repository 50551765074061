import * as React from "react";
import { Box, Grid, Paper } from "@mui/material";

export default function CustomizedSteppers({ element }) {
  return (
    <Paper elevation={3} sx={{ padding: 3, maxWidth: "100%", marginTop: 2 , overflowY:'auto' }}>
      {element}
    </Paper>
  );
}
