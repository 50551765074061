import React, { useEffect, useRef, useState } from "react";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
  Container,
  Typography,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormLabel,
  useTheme,
} from "@mui/material";
import black_bg from "../../Assests/auth-bg.jpg";
import {
  useCreateIncubationMutation,
  useGetSectorQuery,
  useGetSubSectorQuery,
} from "../../services/api";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ToastContainer, toast } from "react-toastify";
import { LoadCanvasTemplate, loadCaptchaEnginge } from "react-simple-captcha";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object({
  hostInstituteName: Yup.string().required(
    "Name of Host Institute is required"
  ),
  address: Yup.string().required("Address is required"),
  constitution: Yup.string().required("Constitution of Institute is required"),
  fieldOfActivity: Yup.string().required("Field of activity is required"),
  sector: Yup.array().of(Yup.string()).required("Sector is required"),
  sub_sector: Yup.array()
    .of(Yup.string())
    .max(8, "You can select up to 8 sub-sectors")
    .required("Sub-sector is required"),
  promoterName: Yup.string().required("Promoter’s Name is required"),
  promoterContact: Yup.string().required("Promoter’s Contact No. is required"),
  promoterEmail: Yup.string()
    .email("Invalid email address")
    .required("Promoter’s Email-ID is required"),
  password: Yup.string().required("Password is required"),
  contactPersonName: Yup.string().required(
    "Name of contact person is required"
  ),
  contactPersonContact: Yup.string().required("Contact No. is required"),
  contactPersonEmail: Yup.string()
    .email("Invalid email address")
    .required("Email-ID is required"),
  csirApproved: Yup.boolean().required("This field is required"),
  approvalNo: Yup.string().when("csirApproved", {
    is: (val) => val === "yes",
    then: () => Yup.string().required("approval number is required"),
    otherwise: () => Yup.string().nullable(),
  }),
  approvalDate: Yup.string().when("csirApproved", {
    is: (val) => val === "yes",
    then: () => Yup.string().required("approval date is required"),
    otherwise: () => Yup.string().nullable(),
  }),
  profile: Yup.string().required(
    "Detailed Profile of Host Institution is required"
  ),
  projects: Yup.string().required(
    "Details of Startup/Innovative Projects are required"
  ),
  separateSeating: Yup.string().required(
    "Details of separate seating arrangement are required"
  ),
  minimumArea: Yup.string().required(
    "Details of minimum required area are required"
  ),
  incubationHours: Yup.string().required(
    "Minimum incubation centre working hours per day are required"
  ),
  availability: Yup.string().required("Availability of facilities is required"),
  subjectExperts: Yup.string().required(
    "Details of concerned Subject experts are required"
  ),
  documents: Yup.string().required("Please upload scanned documents"),
});

const IncubationCentre = () => {
  const [createIncubation] = useCreateIncubationMutation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState("");
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      hostInstituteName: "",
      phone: "",
      email: "",
      address: "",
      constitution: "",
      fieldOfActivity: "",
      promoterName: "",
      promoterContact: "",
      promoterEmail: "",
      password: "",
      sector: [],
      sub_sector: [],
      contactPersonName: "",
      contactPersonContact: "",
      contactPersonEmail: "",
      csirApproved: false,
      approvalNo: "",
      approvalDate: new Date(),
      profile: "",
      projects: "",
      facilities: "",
      separateSeating: "",
      minimumArea: "",
      incubationHours: "",
      availability: "",
      subjectExperts: "",
      documents: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const payload = {
        host_institute_name: values.hostInstituteName,
        phone: values.phone,
        email: values.email,
        password: values.password,
        sector: JSON.stringify(values.sector),
        sub_sector: JSON.stringify(values.sub_sector),
        host_institute_address: values.address,
        institute_constitution: values.constitution,
        host_field_activity: values.fieldOfActivity,
        promoter_name: values.promoterName,
        promoter_contact: values.promoterContact,
        promoter_email: values.promoterEmail,
        name_handle_incubation: values.contactPersonName,
        email_handle_incubation: values.contactPersonEmail,
        contact_handle_incubation: values.contactPersonContact,
        approved_lab: values.csirApproved,
        approved_lab_no: values.approvalNo,
        approved_lab_date: values.approvalDate,
        host_profile: values.profile,
        detail_startup: values.projects,
        seating_arrangement: values.separateSeating,
        required_area: values.minimumArea,
        working_hours_per_day: values.incubationHours,
        availability_room: values.availability,
        subject_experts: values.subjectExperts,
        upload_documents: values.documents,
      };

      const formData = new FormData();

      // Append each key-value pair to the FormData object
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      const {
        data: userData,
        error: isCreateError,
        isLoading: isUserLoading,
      } = await createIncubation(formData);
      if (userData && userData?.success === true) {
        const time = 2000;
        toast.success(
          "Thanks for submisssion of your applicantion department will review and updated you shortly",
          {
            position: "top-center",
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setTimeout(() => {
          navigate(`/`);
        }, time + 1000);
      }

      if (isCreateError?.data && !isUserLoading) {
        toast.error(isCreateError.data.message ?? "Something went wrong", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      // }
    },
  });

  const handleFileChange = (event, type) => {
    formik.setFieldValue(type, event.target.files[0]);
  };
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const {
    data: sectorData,
    isLoading: sectorLoading,
    error: sectorError,
  } = useGetSectorQuery();

  const { data: subsectorList, isLoading, isError } = useGetSubSectorQuery();
  useEffect(() => {
    loadCaptchaEnginge(6, "grey");
  }, []);

  return (
    <Box className="screen-app-container">
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${
            theme.palette.mode !== "light" ? null : black_bg
          }) `, // Center the background image
          backgroundSize: "cover",
          width: "100%",
          padding: "2rem",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: (theme) => theme.palette.background.default,
          color: (theme) => theme.palette.text.primary,
        }}
      >
        <Container
          component="main"
          //  className="register-page"
        >
          <Paper
            sx={{
              padding: "3em 2em",
              marginTop: "1em",
              marginBottom: "1em",
            }}
            elevation={5}
          >
            {/* <Typography variant="h4" gutterBottom>
              Incubation Centre Form
            </Typography> */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "20%",
                flexDirection: "column",
                gap: "10px",
                marginBottom: "2rem",
              }}
            >
              <Grid item md={6} lg={6} sm={6}>
                <Typography variant="h5" align="center" fontWeight="600">
                  {t("Incubation Centre Form")}
                </Typography>
              </Grid>
              <Grid item md={6} lg={6} sm={6}>
                <CustomizedProgressBars val={50} />
              </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                {/* Your form fields */}
                {/* Example field */}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="hostInstituteName"
                    name="hostInstituteName"
                    label="Name of Host Institute"
                    variant="outlined"
                    value={formik.values.hostInstituteName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.hostInstituteName &&
                      Boolean(formik.errors.hostInstituteName)
                    }
                    helperText={
                      formik.touched.hostInstituteName &&
                      formik.errors.hostInstituteName
                    }
                    sx={{
                      color: "#000",
                      // backgroundColor:'#fff'
                    }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    label="Address of Host Institute"
                    variant="outlined"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone No. of Host Institute"
                    variant="outlined"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email ID of Host Institute"
                    variant="outlined"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="sector">Sector</InputLabel>
                    <Select
                      margin="dense"
                      multiple
                      required
                      fullWidth
                      id="sector"
                      label="Sector"
                      placeholder="Select Sector"
                      name="sector"
                      // autoFocus
                      value={formik.values.sector}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.sector && Boolean(formik.errors.sector)
                      }
                      // helperText={formik.touched.sector && formik.errors.sector}
                      autoComplete="off"
                      // onChange={(e) => handleChange(e)}
                    >
                      {sectorData?.data.map((item) => (
                        <MenuItem value={item._id} key={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="sub_sector">Sub Sector</InputLabel>
                    <Select
                      margin="dense"
                      multiple
                      required
                      fullWidth
                      label="Sub Sector"
                      id="sub_sector"
                      s
                      placeholder="Select Sub Sector"
                      name="sub_sector"
                      value={formik.values.sub_sector}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // error={
                      //   formik.touched.sub_sector && Boolean(formik.errors.sub_sector)
                      // }
                      // helperText={formik.touched.sub_sector && formik.errors.sub_sector}
                      autoComplete="off"
                      // onChange={(e) => handleChange(e)}
                    >
                      {subsectorList?.data.map((item) => (
                        <MenuItem value={item._id} key={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.errors.sub_sector && (
                      <Typography
                        variant="h7"
                        sx={{ color: "red", fontSize: "12px" }}
                      >
                        {formik.errors.sub_sector}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="constitution"
                    name="constitution"
                    label="Constitution of Institute"
                    variant="outlined"
                    value={formik.values.constitution}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.constitution &&
                      Boolean(formik.errors.constitution)
                    }
                    helperText={
                      formik.touched.constitution && formik.errors.constitution
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="fieldOfActivity"
                    name="fieldOfActivity"
                    label="Field of activity of Host Institute"
                    variant="outlined"
                    value={formik.values.fieldOfActivity}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.fieldOfActivity &&
                      Boolean(formik.errors.fieldOfActivity)
                    }
                    helperText={
                      formik.touched.fieldOfActivity &&
                      formik.errors.fieldOfActivity
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="promoterName"
                    name="promoterName"
                    label="Promoter's Name"
                    variant="outlined"
                    value={formik.values.promoterName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.promoterName &&
                      Boolean(formik.errors.promoterName)
                    }
                    helperText={
                      formik.touched.promoterName && formik.errors.promoterName
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="promoterContact"
                    name="promoterContact"
                    label="Promoter's Contact No."
                    variant="outlined"
                    value={formik.values.promoterContact}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.promoterContact &&
                      Boolean(formik.errors.promoterContact)
                    }
                    helperText={
                      formik.touched.promoterContact &&
                      formik.errors.promoterContact
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="promoterEmail"
                    name="promoterEmail"
                    label="Promoter's Email-ID"
                    variant="outlined"
                    value={formik.values.promoterEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.promoterEmail &&
                      Boolean(formik.errors.promoterEmail)
                    }
                    helperText={
                      formik.touched.promoterEmail &&
                      formik.errors.promoterEmail
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="contactPersonName"
                    name="contactPersonName"
                    label="Name of contact person who is going to handle incubation activity"
                    variant="outlined"
                    value={formik.values.contactPersonName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactPersonName &&
                      Boolean(formik.errors.contactPersonName)
                    }
                    helperText={
                      formik.touched.contactPersonName &&
                      formik.errors.contactPersonName
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="contactPersonContact"
                    name="contactPersonContact"
                    label="Contact No."
                    variant="outlined"
                    value={formik.values.contactPersonContact}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactPersonContact &&
                      Boolean(formik.errors.contactPersonContact)
                    }
                    helperText={
                      formik.touched.contactPersonContact &&
                      formik.errors.contactPersonContact
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="contactPersonEmail"
                    name="contactPersonEmail"
                    label="Email-ID"
                    variant="outlined"
                    value={formik.values.contactPersonEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactPersonEmail &&
                      Boolean(formik.errors.contactPersonEmail)
                    }
                    helperText={
                      formik.touched.contactPersonEmail &&
                      formik.errors.contactPersonEmail
                    }
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="csirApproved"
                        name="csirApproved"
                        checked={formik.values.csirApproved}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Has the Host Institute CSIR approved lab?"
                  />
                </Grid>
                {formik.values.csirApproved ? (
                  <>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        id="approvalNo"
                        name="approvalNo"
                        label="Approval No."
                        variant="outlined"
                        value={formik.values.approvalNo}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.approvalNo &&
                          Boolean(formik.errors.approvalNo)
                        }
                        helperText={
                          formik.touched.approvalNo && formik.errors.approvalNo
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        id="approvalDate"
                        name="approvalDate"
                        label="Approval Date"
                        type="date"
                        variant="outlined"
                        value={formik.values.approvalDate}
                        onChange={formik.handleChange}
                        InputLabelProps={{ shrink: true }}
                        error={
                          formik.touched.approvalDate &&
                          Boolean(formik.errors.approvalDate)
                        }
                        helperText={
                          formik.touched.approvalDate &&
                          formik.errors.approvalDate
                        }
                      />
                    </Grid>
                  </>
                ) : null}

                <Grid
                  className="file-upload-style-main"
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  mt={2}
                >
                  <FormControl
                    className="file-upload-style"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.default,
                      color: (theme) => theme.palette.text.primary,
                    }}
                  >
                    <CloudUploadIcon
                      className="icon-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => fileInputRef1.current.click()}
                    />
                    {formik?.values?.profile && (
                      <Link
                        to={URL.createObjectURL(formik.values.profile)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <VisibilityIcon />
                      </Link>
                    )}
                    <FormLabel>Profile</FormLabel>
                    <input
                      type="file"
                      accept=".pdf"
                      style={{ display: "none" }}
                      ref={fileInputRef1}
                      onChange={(event) => handleFileChange(event, "profile")}
                    />
                    {formik.values.profile && (
                      <Typography color="black" fontSize={14}  sx={{
                       
                        color: (theme) => theme.palette.text.primary,
                      }}>
                        {formik?.values?.profile?.name}
                      </Typography>
                    )}
                    {formik?.errors?.profile && formik?.touched?.profile && (
                      <Typography color="error">
                        {formik?.errors?.profile}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="projects"
                    name="projects"
                    label="Details of Startup/Innovative Projects"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formik.values.projects}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.projects && Boolean(formik.errors.projects)
                    }
                    helperText={
                      formik.touched.projects && formik.errors.projects
                    }
                  />
                </Grid>
                {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="facilities"
                    name="facilities"
                    label="Describe briefly about facilities currently available in the Institute"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formik.values.facilities}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.facilities &&
                      Boolean(formik.errors.facilities)
                    }
                    helperText={
                      formik.touched.facilities && formik.errors.facilities
                    }
                  />
                </Grid> */}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="separateSeating"
                    name="separateSeating"
                    label="Separate seating arrangement for Startups"
                    variant="outlined"
                    value={formik.values.separateSeating}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.separateSeating &&
                      Boolean(formik.errors.separateSeating)
                    }
                    helperText={
                      formik.touched.separateSeating &&
                      formik.errors.separateSeating
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="minimumArea"
                    name="minimumArea"
                    label="Minimum required area per Startup"
                    variant="outlined"
                    value={formik.values.minimumArea}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.minimumArea &&
                      Boolean(formik.errors.minimumArea)
                    }
                    helperText={
                      formik.touched.minimumArea && formik.errors.minimumArea
                    }
                  />
                </Grid>
                {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <input
                    accept="application/pdf,application/vnd.ms-excel"
                    id="documents"
                    name="documents"
                    type="file"
                    onChange={(e) => handleFileChange(e, "documents")}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="documents">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload Scanned Documents
                    </Button>
                    {formik.errors.documents && formik.touched.documents ? (
                      <div>{formik.errors.documents}</div>
                    ) : null}
                  </label>
                </Grid> */}
                <Grid
                  className="file-upload-style-main"
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  mt={2}
                >
                  <FormControl
                    className="file-upload-style"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.default,
                      color: (theme) => theme.palette.text.primary,
                    }}
                  >
                    <CloudUploadIcon
                      className="icon-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => fileInputRef2.current.click()}
                    />
                    {formik?.values?.documents && (
                      <Link
                        to={URL.createObjectURL(formik.values.documents)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <VisibilityIcon />
                      </Link>
                    )}
                    <FormLabel> Upload Scanned Documents</FormLabel>
                    <input
                      type="file"
                      accept=".pdf"
                      style={{ display: "none" }}
                      ref={fileInputRef2}
                      onChange={(event) => handleFileChange(event, "documents")}
                    />
                    {formik.values.profile && (
                      <Typography color="black" fontSize={14}  sx={{
                       
                        color: (theme) => theme.palette.text.primary,
                      }}>
                        {formik.values.documents.name}
                      </Typography>
                    )}
                    {formik?.errors?.documents &&
                      formik?.touched?.documents && (
                        <Typography color="error">
                          {formik?.errors?.documents}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="incubationHours"
                    name="incubationHours"
                    label="Minimum Incubation Centre working hours per day"
                    variant="outlined"
                    value={formik.values.incubationHours}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.incubationHours &&
                      Boolean(formik.errors.incubationHours)
                    }
                    helperText={
                      formik.touched.incubationHours &&
                      formik.errors.incubationHours
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="availability"
                    name="availability"
                    label="Availability of facilities to the Incubates"
                    variant="outlined"
                    value={formik.values.availability}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.availability &&
                      Boolean(formik.errors.availability)
                    }
                    helperText={
                      formik.touched.availability && formik.errors.availability
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="subjectExperts"
                    name="subjectExperts"
                    label="Details of concerned Subject experts"
                    variant="outlined"
                    value={formik.values.subjectExperts}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.subjectExperts &&
                      Boolean(formik.errors.subjectExperts)
                    }
                    helperText={
                      formik.touched.subjectExperts &&
                      formik.errors.subjectExperts
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box className="captcha-continer">
                    <LoadCanvasTemplate
                      reloadText="Get a new code"
                      reloadColor="white"
                    />
                    <Box className="input-block">
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="captcha"
                        type="text"
                        id="captcha"
                        placeholder="Enter Verification code"
                        onChange={(e) => setCaptcha(e.target.value)}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box textAlign="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default IncubationCentre;
