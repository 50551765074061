import { Box, Typography } from "@mui/material";

const disclaimerStyles = {
  mt: 2,
  p: 2,
  border: 1,
  borderColor: "grey.300",
  bgcolor: "grey.100",
  borderRadius: "4px",
  backgroundColor: (theme) =>
    theme.palette.background.default,
  color: (theme) => theme.palette.text.primary,
};

const Disclaimer = () => {
  return (
    <Box >
      <Box sx={disclaimerStyles}>
        <Typography variant="body2">
          Disclaimer:In recognition of your project on the basis of prima facie,
          you've been selected to proceed to the next round. Final selection
          would be subject to recommendation and approval of the technical
          screening committee and Empowered Committee.
        </Typography>
      </Box>
    </Box>
  );
};

export default Disclaimer;
