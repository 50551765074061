import React, { useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import bg from "../../Assests/black_bg.jpg";
import { useSpring } from "react-spring";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../services/ThemeContext";

export default function IconCardV2(props) {

  // const thumbnailOptions = {
  //   start  : 1,
  //   perPage: 4,
  //   gap:40,
  //   rewind : true,
  //   autoHeight: true,
  //   rewindSpeed: 1000,
  //   autoHeight: true,
  //   autoplay: true,
  // };
  const thumbnailOptions = {
    start  : 1,
    perPage: 4,
    gap:20,
    rewind : true,
    autoHeight: true,
    rewindSpeed: 1000,
    autoHeight: true,
    autoplay: true,
    breakpoints: {
			640: {
				perPage: 1,
			},
      768: {
        perPage: 2, // 2 slides per page on screens between 641px and 768px
      },
      1024: {
        perPage: 3, // 3 slides per page on screens between 769px and 1024px
      },
      1280: {
        perPage: 4, // 4 slides per page on screens between 1025px and 1280px
      },
		},
    
  };


  const [activeIndex, setActiveIndex] = useState(0);

  const handleMouseEnter = (index) => setActiveIndex(index);
  const handleMouseLeave = () => setActiveIndex(-1);
  const { t } = useTranslation();
  const { fontSize } = React.useContext(ThemeContext);


  return (

    <Splide options={thumbnailOptions}>
      {props.data?.map(({ date, subject, content, id, media }, index) => {
        return (
          <SplideSlide
            key={index}
            // onClick={() => handleThumbnailClick(thumbnail.image)}
            style={{
              borderRadius: 10,
            }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            {/* <animated.div style={popEffect}> */}

            {/* <Box> */}
            <Card
              sx={{
                // position: "relative",
                borderTopLeftRadius: props.borderTopLeftRadius,
                borderTopRightRadius: props.borderTopRightRadius,
                borderBottomRightRadius: props.borderBottomRightRadius,
                borderBottomLeftRadius: props.borderBottomLeftRadius,
                borderColor: props.borderColor,
                borderWidth: props.borderWidth,
                outlineColor: props.borderColor,
                height: props.height,
                width: props.width,
                // maxWidth: "100%", // Set maxWidth to limit the card width
                // overflow: "hidden", // Hide content overflow
                
              }}
              variant="outlined"
              className={subject.replaceAll(" ", "-")}
            >
              <CardMedia
                sx={{
                  height: props.mediaHeight,
                  width: props.mediaWidth,

                  margin: props.mediaPosition,
                  
                }}
                image={media}
              />
              <CardContent>
                <Typography
                  className="date-blue"
                  component="p"
                  sx={{ backgroundColor: (theme) => theme.palette.background.default,
                    color: (theme) => theme.palette.text.primary,
                  fontSize: `${fontSize}px`
                  }}
                >
                  {date}
                </Typography>
                <Typography className="normal-title text-uppercase"
                  variant="h5"
                  mb={2}
                  sx={{ backgroundColor: (theme) => theme.palette.background.default,
                    color: (theme) => theme.palette.text.primary,
                    fontSize: `${fontSize}px`}}
                >
                  {t(subject).toUpperCase()}
                </Typography>
                <Typography
                  textAlign={"center"}
                  sx={{ objectFit: "cover", overflow: "hidden", fontSize: `${fontSize}px`}}
                >
                  {t(content)}
                </Typography>
              </CardContent>
            </Card>
            {/* </Box> */}
            {/* </animated.div> */}
          </SplideSlide>
        );
      })}
    </Splide>
    //   </Box>
    // </Box>
  );
}

