import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Paper,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { api, useCreateApplicantMutation, useGetPriorityQuery } from "../../services/api";

const Schema = Yup.object().shape({
  women_led_startup: Yup.string().nullable(),
  category: Yup.string().nullable(),
  priority_sector: Yup.string().nullable(),
  priority_sector_drop: Yup.string().nullable()
});

const NewApplicant = ({ steps, active, setActive }) => {
  const [loading, setLoading] = React.useState(false);
  const { data: priorityList, isLoading, isError } = useGetPriorityQuery();
  const [newApplicant] = useCreateApplicantMutation();
  const [getSingleApplicant] = api.endpoints.singleApplicant.useLazyQuery();
  const _id = localStorage.getItem("application_id");

  const formik = useFormik({
    initialValues: {
      women_led_startup: "no",
      category: "",
      // priority_sector: "no",
      // priority_sector_drop: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        let data = {
          step: "application",
          payload:_id? {
            women_led: values.women_led_startup,
            category: values.category,
            // priority_sector_radio: values.priority_sector,
            // priority_sector: values.priority_sector_drop,
            application_id:_id
          }:{
            women_led: values.women_led_startup,
            category: values.category,
            // priority_sector_radio: values.priority_sector,
            // priority_sector: values.priority_sector_drop,
          },
        };
        const response = await newApplicant(data);
        if(response?.data?.success){
          toast.success( _id?"New Applicant Updated Successfully":"New Applicant Created Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        localStorage.setItem("application_id", response.data.data._id);
          setTimeout(()=>{
            setActive((prevActive) => prevActive + 1);
          }, 3000)
        }else if(response?.error){
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleNext = (e) => {
    e.preventDefault();
    setLoading(true);
    if (formik.isValid) {
      formik.handleSubmit();
    } else {
      setLoading(false);
    }
    setTimeout(()=>{
      setLoading(false);
    },1000)
  };
  React.useEffect(() => {
    const UpdateApplicant = async () => {
      if (_id) {
        try {
          const {
            data
          } = await getSingleApplicant(_id);
          const { women_led, category, priority_sector,priority_sector_radio } =
          data?.application;
          await formik.setValues((prev) => ({
            ...prev,
            women_led_startup:women_led,
            category,
            // priority_sector: priority_sector_radio,
            // priority_sector_drop: priority_sector,
  
          }));
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateApplicant();
  }, [_id]);
  return (
    <Box className="applicant">
      <ToastContainer />
      <Typography variant="h6" align="left" sx={{ fontWeight: 600, color: "#2074af" 
                  }}>
        New Application
      </Typography>
      <form onSubmit={formik.handleSubmit}  >
        <Grid container spacing={3}>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography variant="h6" align="left" sx={{ fontWeight: 400, fontSize: '18px' }}>
              Women led Startup
            </Typography>
            <br />
            <FormControl fullWidth>
              <FormLabel>Women led Startup</FormLabel>
              <RadioGroup
                row
                name="women_led_startup"
                value={formik.values.women_led_startup}
                onChange={formik.handleChange}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
              {formik.touched.women_led_startup && formik.errors.women_led_startup ? (
                <Typography color="error">{formik.errors.women_led_startup}</Typography>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography variant="h6" align="left" sx={{ fontWeight: 400, fontSize: '18px' }}>
              Select Category
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="category">Select Category</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="category"
                label="Select Category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.category && Boolean(formik.errors.category)}
              >
                {["General", "SC", "ST", "OBC", "Others"].map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.category && formik.errors.category ? (
                <Typography color="error" sx={{fontSize:"12px"}}>{formik.errors.category}</Typography>
              ) : null}
            </FormControl>
          </Grid>
          {/* <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography variant="h6" align="left" sx={{ fontWeight: 400, fontSize: '18px' }}>
              Priority Sector
            </Typography>
            <br />
            <FormControl fullWidth>
              <FormLabel>Priority Sector</FormLabel>
              <RadioGroup
                row
                name="priority_sector"
                value={formik.values.priority_sector}
                onChange={formik.handleChange}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
              {formik.touched.priority_sector && formik.errors.priority_sector ? (
                <Typography color="error">{formik.errors.priority_sector}</Typography>
              ) : null}
            </FormControl>
          </Grid> */}
          {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography variant="h6" align="left" sx={{ fontWeight: 400, fontSize: '18px' }}>
              Select Priority Sector
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="priority_sector_drop">Select Priority Sector</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="priority_sector_drop"
                label="Select Priority Sector"
                name="priority_sector_drop"
                value={formik.values.priority_sector_drop}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.priority_sector_drop && Boolean(formik.errors.priority_sector_drop)}
              >
                {priorityList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.priority_sector_drop && formik.errors.priority_sector_drop ? (
                <Typography color="error"  sx={{fontSize:"12px"}}>{formik.errors.priority_sector_drop}</Typography>
              ) : null}
            </FormControl>
          </Grid> */}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="button-right">
            <LoadingButton
              variant="contained"
              onClick={handleNext}
              disabled={active === steps.length - 1}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              type="submit"
            >
              Next
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NewApplicant;
