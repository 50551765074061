import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  List,
  ListItem,
  Divider,
  Typography,
  Drawer,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MuiAppBar from "@mui/material/AppBar";
import smallLogo from "../../Assests/hp_icon.png";
import sidebar from "../../Assests/sidebar-bg.jpg";
import Header from "../Header";
import Footerv2 from "../../Screens/Footer/indexv2";
import { store } from "../../store";
import "../../style.scss";
import { iconToRender } from "../../Helpers/SidebarIcons";
import LoaderFallBack from "../../Loader";
const drawerWidth = navigator?.userAgentData?.mobile||/Mobi|Android/i.test(navigator.userAgent)?240:280;

//==========================> Icons

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,

    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  //   backgroundColor:'#fff',
  width: "100%",
}));

export default function Sidebar({ element }) {
  const [unival, setUniVal] = useState("");
  const [selectedTab, setSelectedTab] = useState(null);
  const location = useLocation();
  const currentPath = location.pathname;
  const [open, setOpen] = useState(true);
  const [sidebarLoader, setSidebarLoader] = useState(true);
  const content = store.getState()?.data?.data?.sideBarContent;
  const role = localStorage.getItem("role").toLowerCase();

  useEffect(()=>{
    setTimeout(()=>{
      setSidebarLoader(false)
    }, 500)
  },[])

  useEffect(() => {
    const selectedTabInfo = content?.find(
      (item) => item.frontend_url === currentPath
    );
    if (selectedTabInfo) {
      setSelectedTab(selectedTabInfo._id);
      setUniVal(selectedTabInfo._id);
    } else {
      setSelectedTab(null);
      setUniVal("");
    }
  }, [content, currentPath]);
  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body")[0];

    // Check the value of 'open' and add or remove the class accordingly
    if (!open) {
      // Add a class to the body element when 'open' is true
      bodyElement.classList.add("sidebar-body");
    } else {
      // Remove the class from the body element when 'open' is false
      bodyElement.classList.remove("sidebar-body");
    }

    // Clean up function to remove the class when component unmounts
    return () => {
      bodyElement.classList.remove("sidebar-body");
    };
  }, [open]);

  const handleToggle = (id) => {
    setSelectedTab(id === selectedTab ? null : id);
    setUniVal(id);
    // Handle other logic based on the id if needed
  };
  // const handleToggleChild = (id) => {
  //   setChildId(id);
  //   setToggle((prevState) => !prevState);
  //   // Handle other logic based on the id if needed
  // };

  const tabColor = {
    bg_white: {
      color: "#fff",
    },
  };

  const renderSidebarItems = (items = []) => {
    if (items?.length < 1) return null;

    return (
      <React.Fragment>
        {Array.isArray(items) &&
          items.map(({ _id, name, permission, frontend_url }, index) => {
            const isOpen = _id === unival;
            if (!permission?.show_menu) return null;
            if (name === "MPR") return;
            if (name === "sub-menu") return;
            if (name === "Industry") return;
            if (name === "Progress Report") return;
            if (name === "Select Mentor") return;
            if (name === "Profile") return;
            if (role !== "user" && name === "Application")
              return;
            if (name === "Recognition Form")
              return;
            return (
              <Box
                key={_id}
                sx={
                  {
                    //  backgroundColor: "#AD235E"
                  }
                }
              >
                <Link
                  to={frontend_url}
                  style={{
                    // color: "#AD235E",
                    textDecoration: "none",
                    height: "100vh",
                  }}
                >
                  <ListItem
                    disablePadding
                    sx={{
                      height: "4em",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        isOpen || selectedTab === _id ? "#AD235E" : null,
                    }}
                  >
                    <ListItemButton
                      onClick={() => {
                        handleToggle(_id);
                      }}
                    >
                      <ListItemIcon
                        sx={tabColor.bg_white}
                        key={_id}
                        className="sidebar_icon"
                      >
                        {iconToRender(name)}
                      </ListItemIcon>
                      <ListItemText    
                      className="sidebar_text"    
                        primary={name !== "sub-menu" ? name : null}
                        sx={{ color: "#fff",fontSize:'16px'}}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
                {/* {children && isOpen && toggle && (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
          
                {children.map(({ id, icon, title, path }, index) => {
                  let istrue = childid === id;
                  return (
                    <Link
                      to={path}
                      style={{ color: "black", backgroundColor: "black" }}
                    >
                      <ListItem
                        disablePadding
                        sx={{
                          backgroundColor: istrue ? "green" : "black",
                          paddingLeft: "16px",
                          fontSize: "5px"
                        }}
                      >
                        <ListItemButton onClick={() => handleToggleChild(id)}>
                          <ListItemIcon sx={tabColor.bg_white}>
                            {icon}
                          </ListItemIcon>
                          <ListItemText
                            sx={{color: "white"}}
                            fontSize={"13px"}
                            primary={title}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  );
                })}
              </List>
            </Collapse>
          )} */}
              </Box>
            );
          })}
        {/* Hide these list items on smaller screens */}
        {/* <Hidden smDown>
          <Box sx={{ backgroundColor: "#AD235E" }}>
          </Box>
        </Hidden> */}
      </React.Fragment>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        {/* {addd header here} */}
        <Header {...{ open, setOpen }} />
      </AppBar>
      <Drawer
        // className="desktop-menu-sidebar"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundImage: `url(${sidebar})`,
            backgroundSize: "cover",
            padding: "0",
            backgroundRepeat: "no-repeat",
            overflowY: "auto",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          className="site-logo-style"
          sx={{ gap: 2, justifyContent: "center" ,
            backgroundColor: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.primary,
        }}
        >
          <Link href="">
            <img src={smallLogo} alt="" style={{ width: "70px" }} />
          </Link>

          <Typography
            variant="h5"
            noWrap
            component="h6"
            color="#AD235E"
            fontWeight={"700"}
            fontSize={"26px"}
          >
            {"hp startup".toUpperCase()}
          </Typography>
        </DrawerHeader>
        <Divider />
        <List>
         { sidebarLoader?<LoaderFallBack isload={true} size={30}/>:
          renderSidebarItems(content)}
        </List>
      </Drawer>
      <Main className="main-panel"
        open={open}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? "#f5f7fb"
              : theme.palette.background.default,
          color: (theme) => theme.palette.text.primary,
        }}
      >
        {element}
        <Footerv2 />
      </Main>
    </Box>
  );
}
