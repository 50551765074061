import {
  Toolbar,
  IconButton,
  Box,
  Typography,
  Menu,
  MenuItem,
  Badge,
  Avatar,
  Switch,
  FormControlLabel,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AccountCircle from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import { ThemeContext } from "../../services/ThemeContext";
import { toast } from "react-toastify";
import { api } from "../../services/api";

const Header = ({ open, setOpen = () => {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { toggleColorMode } = useContext(ThemeContext);
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const dp = useSelector((state) => state?.data?.data?.userProfile);
  const [getProfile] = api.endpoints.getProfile.useLazyQuery();
  const [dpimg, setDpImg]=useState("")
  const { increaseFontSize, decreaseFontSize, resetFontSize, fontSize } = useContext(ThemeContext);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    navigate("/profile");
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    localStorage.clear();
    toast.success("You have been logged out successfully");
    navigate("/");
  };
  useEffect(() => {
    const Updatedp = async () => {
      try {
        const { data } = await getProfile();
        const {      
          image, // Default image  
        } = data?.data;
        setDpImg(image);     
      } catch (error) {
        console.error("Error fetching mentor data:", error);
      }
    };
    Updatedp();
  }, []);

  return (
    <Toolbar
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
      }}
    >
      <Grid container alignItems="center">
        {/* Drawer Icon */}
        <Grid item xs={4} sm={12} md={4}>
          <IconButton onClick={handleDrawer} aria-label="open drawer">
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </Grid>

        {/* Middle Content */}

        {/* Avatar & Menu */}
        <Grid container xs={8} sm={12} md={8} justifyContent={'flex-end'} >
        <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-end" },
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  color="warning"
                  onClick={toggleColorMode}
                />
              }
              label="Dark mode"
              sx={{ fontWeight: 200, color: "inherit" }}
            />


          </Box>
        <Grid item display={"flex"} sx={{gap:1}} alignItems={"center"}>
            {Object.keys(userData).length > 0 && (
              <>
                <Grid >

                 <Typography
                  variant="h7"
                  sx={{
                    fontSize: `${14}px`,
                    color: "rgb(173, 35, 94)",
                    // textAlign: "left",
                  }}
                >
                 Hii
                </Typography>
                </Grid>
                <Grid >

                 <Typography
                  variant="h6"
                  sx={{
                    fontSize: `${fontSize}px`,
                    color: "rgb(173, 35, 94)",
                    // textAlign: "right",
                  }}
                >
                  {userData?.name?.toUpperCase()}
                </Typography>
                </Grid>
              </>

// <Badge
//   badgeContent={"Hii"}
//   anchorOrigin={{ vertical: "top", horizontal: "left" }}
// >

// </Badge>
)}
</Grid>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          >
            <Avatar alt="Profile Picture" src={dp?.image||dpimg} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <AccountCircle /> Profile
            </MenuItem>
            <MenuItem onClick={logout}>
              <Logout /> Logout
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default Header;
