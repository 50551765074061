// import React, { useEffect, useRef, useState } from "react";
// import {
//   Box,
//   Typography,
//   Grid,
//   Button,
//   TableContainer,
//   Table,
//   TableHead,
//   TableCell,
//   Paper,
//   TableBody,
//   TableRow,
// } from "@mui/material";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import SendIcon from "@mui/icons-material/Send";
// import LoadingButton from "@mui/lab/LoadingButton";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import {
//   api,
//   useCreateApplicantMutation,
//   useSingleApplicantQuery,
// } from "../../services/api";
// import { ToastContainer, toast } from "react-toastify";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import VisibilityIcon from "@mui/icons-material/Visibility";

// const DocumentUploaded = ({ steps, active, setActive }) => {
//   const _id = localStorage.getItem("application_id");
//   const [loading, setLoading] = useState(false);
//   const [newApplicant] = useCreateApplicantMutation();
//   const [getAllApplicant] = api.endpoints.singleApplicant.useLazyQuery();
//   const { data: singleData } = useSingleApplicantQuery(_id);
//   const navigate = useNavigate();
//   const { id } = useParams();

//   const validationSchema = Yup.object().shape({
//     rows: Yup?.array().of(
//       Yup?.object().shape({
//         document: Yup.string().required(),
//         name: Yup.string().when("key", {
//           is: (val) =>
//             _id
//               ? null
//               : val === "doc_aadhaar" || val === "pitch_deck" || val === "pic",
//           then: () => Yup.string().required("Name is required"),
//           otherwise: () => Yup.string().nullable(),
//         }),
//         file: Yup.mixed().when("key", {
//           is: (val) =>
//             val === "doc_aadhaar" || val === "pitch_deck" || val === "pic",
//           then: () => Yup.mixed().required("File is required"),
//           otherwise: () => Yup.mixed().nullable(),
//         }),
//         preview: Yup.string().nullable(),
//       })
//     ),
//   });

//   const formik = useFormik({
//     initialValues: {
//       rows: [
//         {
//           document: "Photograph (passport size photo required png or jpg )",
//           key: `pic`,
//           name: "",
//           type: "image/png, image/jpeg",
//           file: null,
//           preview: null,
//         },
//         {
//           document: "PAN",
//           key: "doc_pan",
//           name: "",
//           type: ".pdf",
//           file: null,
//           preview: null,
//         },
//         {
//           document: `Aadhaar`,
//           key: "doc_aadhaar",
//           name: "",
//           type: ".pdf",
//           file: null,
//           preview: null,
//         },
//         {
//           document: "GST Registration",
//           key: "gst_registration",
//           name: "",
//           type: ".pdf",
//           file: null,
//           preview: null,
//         },
//         {
//           document: "Proof of Office Address",
//           key: "proof_office_address",
//           name: "",
//           type: ".pdf",
//           file: null,
//           preview: null,
//         },
//         {
//           document: "Articles of Incorporation",
//           key: "articles_incorporation",
//           name: "",
//           type: ".pdf",
//           file: null,
//           preview: null,
//         },
//         {
//           document: "Pitch Deck",
//           key: "pitch_deck",
//           name: "",
//           type: ".pdf",
//           file: null,
//           preview: null,
//         },
//         {
//           document: "DPIIT Recognised Certificate",
//           key: "dpiit_recognised_certificate",
//           name: "",
//           type: ".pdf",
//           file: null,
//           preview: null,
//         },
//         {
//           document: "Any other Document",
//           key: "any_other_document",
//           name: "",
//           type: ".pdf",
//           file: null,
//           preview: null,
//         },
//       ],
//     },
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {
//       setLoading(true);
//       try {
//         const formData = new FormData();
//         values.rows.forEach((row, index) => {
//           if (row.file) {
//             formData.append(row.key, row.file);
//           }
//         });
//         formData.append("application_id", _id);
//         let data = {
//           step: "document",
//           payload: formData,
//         };
//         const response = await newApplicant(data);
//         if (response?.data?.success) {
//           toast.success("Document Uploaded Successfully", {
//             position: "top-center",
//             autoClose: 2000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//           });
//           localStorage.removeItem("application_id");
//           setTimeout(() => {
//             setActive((prevActive) => prevActive + 1);
//             navigate("/application-status");
//           }, 3000);
//         } else if (response?.error) {
//           toast.error(response?.error?.data?.message, {
//             position: "top-center",
//             autoClose: 3000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//           });
//         }
//       } catch (error) {
//         console.error("Error submitting data:", error);
//       } finally {
//         setLoading(false);
//       }
//     },
//   });

//   const handleNext = () => {
//     formik.handleSubmit();
//   };

//   const handleBack = () => {
//     setActive((prevActive) => prevActive - 1);
//   };
//   console.log("first", formik.values.rows)

//   useEffect(() => {
//     const UpdateApplication = async () => {
//       if (_id) {
//         try {
//           const data = {
//             companyName: "Company Name",
//             projectFormation: "Project Formation",
//             address: "Address",
//             incubation_centre: "Incubation Centre",
//           };
//           formik.setValues((prevValues) => ({
//             ...prevValues,
//             ...data,
//           }));
//         } catch (error) {
//           console.error("Error fetching permission data:", error);
//         }
//       }
//     };
//     UpdateApplication();
//   }, [_id]);

//   const fileInputRef = useRef([]);

//   const handleIconClick = (index) => {
//     fileInputRef.current[index].click();
//   };

//   const handleFileUpload = async(index, event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const base64File = await fileToBase64(file);
//       console.log("🚀 ~ handleFileUpload ~ base64File:", base64File)
//       const rows = JSON.parse(localStorage.getItem("documents")) || [];
//       rows.push({
//         file: base64File,
//         name: file.name,
//         preview: base64File,
//       });
//       localStorage.setItem("documents", JSON.stringify(rows));
//       const newRows = [...formik.values.rows];
//       console.log("🚀 ~ handleFileUpload ~ formik.values.rows:", formik.values.rows)
//       newRows[index].file = file;
//       newRows[index].name = file.name;
//       newRows[index].preview = URL.createObjectURL(file);
//       formik.setFieldValue("rows", newRows);
//     }
//   };
//   function fileToBase64(file) {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => resolve(reader.result);
//       reader.onerror = error => reject(error);
//     });
//   }
//   useEffect(()=>{
//       const rows=JSON.parse(localStorage.getItem("documents"));
//       if(rows){
//         const updatedRows = rows.map((row) => {
//           return {
//             ...row,
//             file: row?.file,
//             name:row?.name,
//             preview: row?.preview,
//           };
//         });
//         formik.setValues((prev)=>({...prev, rows: updatedRows }));
//       }

//   },[])
//   useEffect(() => {
//     const UpdateApplication = async () => {
//       if (_id) {
//         try {
//           const { data } = await getAllApplicant(_id);
//           if (data?.application) {
//             const updatedRows = formik.values.rows.map((row) => {
//               return {
//                 ...row,
//                 // name: data.application[row.key] || "",
//                 file: data.application[row.key] || "",
//                 preview: data.application[row.key] || null,
//               };
//             });
//             formik.setValues({ rows: updatedRows });
//           }
//         } catch (error) {
//           console.error("Error fetching applicant data:", error);
//         }
//       }
//     };
//     UpdateApplication();
//   }, [_id, getAllApplicant]);

//   const hasPreview = formik.values.rows.some((row) => row.preview);
//   const hasName = formik.values.rows.some((row) => row.name);

//   return (
//     <>
//       <ToastContainer />
//       <Typography
//         variant="h6"
//         align=""
//         sx={{ fontWeight: 600, color: "#2074af" }}
//       >
//         Document Uploaded
//       </Typography>
//       <Box
//         component="form"
//         onSubmit={formik.handleSubmit}
//         noValidate
//         sx={{ mt: 1 }}
//       >
//         <Grid
//           container
//           rowSpacing={1}
//           // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
//           mt={2}
//         >
//           <Grid item md={12} lg={12} xl={12} xs={12}>
//             <Typography
//               variant="h6"
//               align=""
//               sx={{ fontWeight: 500, fontSize: "18px" }}
//             >
//               Documents:
//             </Typography>
//             <TableContainer component={Paper} fullWidth>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>Document</TableCell>
//                     <TableCell>File</TableCell>
//                     {hasName ? <TableCell>Name</TableCell> : null}
//                     {hasPreview ? <TableCell>Preview</TableCell> : null}
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {formik.values.rows?.map((row, index) => (
//                     <TableRow key={index}>
//                       <TableCell>{row.document}</TableCell>
//                       <TableCell>
//                         <Button
//                           title="Upload Document"
//                           variant="contained"
//                           size="small"
//                           color="info"
//                           onClick={() => handleIconClick(index)}
//                         >
//                           <CloudUploadIcon
//                             style={{ cursor: "pointer", color: "#fff" }}
//                             // onClick={() => handleIconClick(index)}
//                           />

//                           <input
//                             type="file"
//                             accept={row.type}
//                             style={{ display: "none" }}
//                             ref={(el) => (fileInputRef.current[index] = el)}
//                             onChange={(event) => handleFileUpload(index, event)}
//                           />
//                         </Button>
//                         {formik.errors?.rows?.[index]?.file &&
//                             formik.touched?.rows?.[index]?.file && (
//                               <Typography
//                                 color="error"
//                                 variant="caption"
//                                 mt={1}
//                               >
//                                 {formik.errors.rows[index].file}
//                               </Typography>
//                             )}
//                       </TableCell>

//                       <TableCell>
//                         {row.name}
//                         {hasName &&
//                           formik.errors?.rows?.[index]?.name &&
//                           formik.touched?.rows?.[index]?.name && (
//                             <Typography color="error" variant="caption">
//                               {formik.errors.rows[index].name}
//                             </Typography>
//                           )}
//                       </TableCell>

//                       <TableCell>
//                         {row.preview && (
//                           <Link
//                             to={row.preview}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                           >
//                            <Button
//                               title="View Document"
//                               variant="contained"
//                               size="small"
//                             >
//                               <VisibilityIcon color="inherit" />
//                             </Button>
//                           </Link>
//                         )}
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </Grid>
//           <Grid
//             item
//             xl={12}
//             lg={12}
//             md={12}
//             sm={12}
//             xs={12}
//             className="button-right"
//           >
//             <Button
//               type="button"
//               variant="contained"
//               onClick={handleBack}
//               disabled={active === 0}
//             >
//               Previous
//             </Button>
//             <Box ml={2} display="inline-block">
//               <Button
//                 type="button"
//                 variant="contained"
//                 onClick={() => navigate(`/applicant/preview/${_id}`)}
//                 disabled={active === 0}
//               >
//                 Preview Application
//               </Button>
//             </Box>
//             <Box ml={2} display="inline-block">
//               <LoadingButton
//                 variant="contained"
//                 onClick={handleNext}
//                 disabled={active === steps.length}
//                 endIcon={<SendIcon />}
//                 loading={loading}
//                 loadingPosition="end"
//               >
//                 <span>Final Submit</span>
//               </LoadingButton>
//             </Box>
//           </Grid>
//         </Grid>
//       </Box>
//     </>
//   );
// };

// export default DocumentUploaded;

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Paper,
  TableBody,
  TableRow,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  api,
  useCreateApplicantMutation,
  useSingleApplicantQuery,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const DocumentUploaded = ({ steps, active, setActive }) => {
  const _id = localStorage.getItem("application_id");
  const [loading, setLoading] = useState(false);
  const [newApplicant] = useCreateApplicantMutation();
  const navigate = useNavigate();
  const fileInputRef = useRef([]);
  const [getAllApplicant] = api.endpoints.singleApplicant.useLazyQuery();

   const validationSchema = Yup.object().shape({
    rows: Yup?.array().of(
      Yup?.object().shape({
        document: Yup.string().required(),
        name: Yup.string().when("key", {
          is: (val) =>
            _id
              ? null
              : val === "doc_aadhaar" || val === "pitch_deck" || val === "pic",
          then: () => Yup.string().required("Name is required"),
          otherwise: () => Yup.string().nullable(),
        }),
        file: Yup.mixed().when("key", {
          is: (val) =>
            val === "doc_aadhaar" || val === "pitch_deck" || val === "pic",
          then: () => Yup.mixed().required("File is required"),
          otherwise: () => Yup.mixed().nullable(),
        }),
        preview: Yup.string().nullable(),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      rows: [
        {
          document: "Photograph (passport size photo required png or jpg )",
          key: `pic`,
          name: "",
          type: "image/png, image/jpeg",
          file: null,
          preview: null,
        },
        {
          document: "PAN",
          key: "doc_pan",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: `Aadhaar`,
          key: "doc_aadhaar",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "GST Registration",
          key: "gst_registration",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "Proof of Office Address",
          key: "proof_office_address",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "Articles of Incorporation",
          key: "articles_incorporation",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "Pitch Deck",
          key: "pitch_deck",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "DPIIT Recognised Certificate",
          key: "dpiit_recognised_certificate",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        {
          document: "Any other Document",
          key: "any_other_document",
          name: "",
          type: ".pdf",
          file: null,
          preview: null,
        },
        // Add other documents similarly
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        values.rows.forEach((row) => {
          if (row.file) {
            formData.append(row.key, row.file);
          }
        });
        formData.append("application_id", _id);

        const data = { step: "document", payload: formData };
        const response = await newApplicant(data);

        if (response?.data?.success) {
          toast.success("Document Uploaded Successfully", {
            position: "top-center",
            autoClose: 2000,
          });
          localStorage.removeItem("application_id");
          localStorage.removeItem("documents");
          setTimeout(() => {
            setActive((prevActive) => prevActive + 1);
            navigate("/application-status");
          }, 3000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleFileUpload = async (index, event) => {
    const file = event.target.files[0];
    if (file) {
      // Convert file to Base64
      const base64File = await fileToBase64(file);
      let fileTypePrefix = "";

      // Check file type to add appropriate prefix
      if (file.type.startsWith("image/")) {
        fileTypePrefix = `data:${file.type};base64,`;
      } else if (file.type === "application/pdf") {
        fileTypePrefix = `data:application/pdf;base64,`;
      }

      const updatedRows = [...formik.values.rows];

      // Update the preview URL with the correct data URL format
      updatedRows[index].file = file;
      updatedRows[index].name = file.name;
      updatedRows[index].preview = `${fileTypePrefix}${
        base64File.split(",")[1]
      }`;

      // Update Formik values and local storage
      formik.setFieldValue("rows", updatedRows);
      localStorage.setItem("documents", JSON.stringify(updatedRows));

      // Trigger re-render
      formik.setFieldTouched(`rows[${index}].preview`, true);
    }
  };
    useEffect(() => {
    const UpdateApplication = async () => {
      if (_id) {
        try {
          const { data } = await getAllApplicant(_id);
          if (data?.application) {
            const updatedRows = formik.values.rows.map((row) => {
              return {
                ...row,
                // name: data.application[row.key] || "",
                file: data.application[row.key] || "",
                preview: data.application[row.key] || null,
              };
            });
            formik.setValues({ rows: updatedRows });
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    UpdateApplication();
  }, [_id, getAllApplicant]);

  const hasPreview = formik.values.rows.some((row) => row.preview);
  const hasName = formik.values.rows.some((row) => row.name);

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    const fecthLoacalData=async()=>{
      const savedDocuments = JSON.parse(localStorage.getItem("documents"));
      console.log("🚀 ~ useEffect ~ savedDocuments:", savedDocuments)
      if (savedDocuments) {
        await formik.setValues((prev) => ({ ...prev, rows: savedDocuments }));
      }
    }
  useEffect(() => {
    fecthLoacalData()
  }, []);

  return (
    <>
      <ToastContainer />
      <Typography variant="h6" sx={{ fontWeight: 600, color: "#2074af" }}>
        Document Uploaded
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid container rowSpacing={1} mt={2}>
          <Grid item md={12}>
            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: "18px" }}>
              Documents:
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Document</TableCell>
                    <TableCell>File</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Preview</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formik.values.rows.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{row.document}</TableCell>
                        <TableCell>
                          <Button
                            title="Upload Document"
                            variant="contained"
                            size="small"
                            color="info"
                            onClick={() => fileInputRef.current[index].click()}
                          >
                            <CloudUploadIcon
                              style={{ cursor: "pointer", color: "#fff" }}
                            />
                            <input
                              type="file"
                              accept={row.type}
                              style={{ display: "none" }}
                              ref={(el) => (fileInputRef.current[index] = el)}
                              onChange={(event) =>
                                handleFileUpload(index, event)
                              }
                            />
                          </Button>
                          {formik.errors?.rows?.[index]?.file &&
                            formik.touched?.rows?.[index]?.file && (
                              <Typography
                                color="error"
                                variant="caption"
                                mt={1}
                              >
                                {formik.errors.rows[index].file}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          {row?.preview && (
                            <a
                              // href={row.preview}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button
                                onClick={() => {
                                  window.open(
                                    row.preview,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                }}
                                title="View Document"
                                variant="contained"
                                size="small"
                              >
                                <VisibilityIcon color="inherit" />
                              </Button>
                            </a>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xl={12} className="button-right">
            <Button
              type="button"
              variant="contained"
              onClick={() => setActive((prev) => prev - 1)}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <Button
                type="button"
                variant="contained"
                onClick={() => navigate(`/applicant/preview/${_id}`)}
                disabled={active === 0}
              >
                Preview Application
              </Button>
            </Box>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={formik.handleSubmit}
                loading={loading}
                endIcon={<SendIcon />}
              >
                <span>Final Submit</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DocumentUploaded;
