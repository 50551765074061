import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
  Grid,
  Box,
  Container,
  InputLabel,
  IconButton,
  Divider,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify"; // Import react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify CSS
import black_bg from "../../Assests/auth-bg.jpg"; // Background image
import smallLogo from "../../Assests/hp_icon.png"; // Logo image
import {
  useGetSpecializationQuery,
  useGetSubSectorQuery,
  useGetSectorQuery,
  useGetStartUpStageQuery,
  useCreateMentorMutation,
  useGetDistrictQuery,
  api,
  useUpdateMentorMutation,
  useChangeStatusByHODMutation,
} from "../../services/api";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";
import "jspdf-autotable";
import TrackMentorApplication from "./TrackMentorApplication";

const validationSchema = Yup.object({
  fullName: Yup.string().required("Full Name is Required"),
  // profilePic: Yup.mixed()
  //   .required('Profile Pic is required')
  //   .test(
  //     'fileSize',
  //     'File size is too large. Max size is 2MB.',
  //     value => value && value.size <= 2 * 1024 * 1024
  //   )
  //   .test(
  //     'fileFormat',
  //     'Unsupported file format. Only images are allowed.',
  //     value => value && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type)
  //   ),

  currentOrganization: Yup.string().required(
    "Current Organization is Required"
  ),
  currentDesignation: Yup.string().required("Current Designation is Required"),
  total_experience: Yup.number()
    .required("Total Experience is Required")
    .min(4, "Minimum value should be 4 or greater then 4"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is Required"),
  mobile: Yup.string().required("Mobile number is Required"),
  city: Yup.string().required("City is Required"),
  // state: Yup.string().required("State is Required"),
  district: Yup.string().required("District is Required"),
  country: Yup.string().required("Country is Required"),
  purpose: Yup.string()
    .max(100, "Must be 100 characters or less")
    .required("Must be 100 characters or less"),
  hours: Yup.string().required("Hours are Required"),
  mentorshipsProvided: Yup.string().required("Required"),
  sector: Yup.string().required("Sector is Required"),
  industries: Yup.array()
    .max(5, "Select up to 5 industries")
    .required("Need to select at least 1 industry"),
  specialization: Yup.array()
    .max(3, "Select up to 3 specializations")
    .required("Need to select at least 1 specialization"),
  startupStage: Yup.string().required("Required"),
  linkedin: Yup.string()
    .url("Invalid URL")
    .required("LinkedIn URL is Required"),
  // resume: Yup.mixed().required("Resume is required"),
  description: Yup.string()
    .max(200, "Must be 200 characters or less")
    .required("Description is Required"),
  // status: Yup.string().required("Status is required"),
  remarks: Yup.string()
    .required("Remarks are required")
    .max(100, "Remarks must be 100 characters or less"),
});

const UpdateValues = ({ id }) => {
  const { setValues } = useFormikContext();
  const [getSingleMentor] = api.endpoints.singleMentor.useLazyQuery();

  useEffect(() => {
    const UpdateMentor = async () => {
      if (id) {
        try {
          const { data } = await getSingleMentor(id);
          const {
            name,
            profile_pic,
            current_organization,
            current_designation,
            email,
            mobile,
            city,
            country,
            district,
            want_to_be_mentor,
            hour_dedicate_per_week,
            mentorship_provide_startup,
            success_stories,
            sector,
            industry,
            industry_sector,
            specialization,
            specialization_sector,
            startup_stage,
            linkedin,
            resume,
            blog_website,
            short_description,
            experience,
          } = data?.data;

          await setValues((prev) => ({
            ...prev,
            fullName: name,
            profilePic: profile_pic,
            currentOrganization: current_organization,
            currentDesignation: current_designation,
            email: email,
            mobile: mobile,
            city: city,
            district: district?._id,
            country: country,
            purpose: want_to_be_mentor,
            hours: hour_dedicate_per_week,
            mentorshipsProvided: mentorship_provide_startup,
            sector: sector?._id,
            industries: industry,
            specialization: specialization,
            startupStage: startup_stage,
            linkedin: linkedin,
            resume: resume,
            description: short_description,
            blog: blog_website,
            success_stories: success_stories,
            industry_fields: industry_sector,
            specialization_fields: specialization_sector,
            successCount: success_stories.length,
            fieldsCount: industry_sector.length,
            inputCount: specialization_sector.length,
            total_experience: experience,
          }));
        } catch (error) {
          console.error("Error fetching mentor data:", error);
        }
      }
    };
    UpdateMentor();
  }, [id, setValues, getSingleMentor]);

  return null;
};
const commonColor = {
  styles: {
    borderStyle: "solid",
    borderColor: "#000000",
    borderBottomWidth: "medium",
    opacity: 1,
  },
};
const MentorPreview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const initialValues = {
    fullName: "",
    profilePic: null,
    currentOrganization: "",
    currentDesignation: "",
    total_experience: "",
    email: "",
    mobile: "",
    city: "",
    // state: "",
    district: "",
    country: "",
    purpose: "",
    hours: "",
    mentorshipsProvided: "",
    sector: "",
    industries: [],
    specialization: [],
    startupStage: "",
    linkedin: "",
    resume: null,
    description: "",
    blog: "",
    terms: false,

    successCount: 1,
    success_stories: [{ startup_name: "" }],

    fieldsCount: 1,
    industry_fields: [{ industry_sector: "" }],

    inputCount: 1,
    specialization_fields: [{ specialization_sector: "" }],
    status: "",
    remarks: "",
  };
  const details = useSelector((state) => state?.data?.data?.loginDetails);
  const {
    data: sectorData,
    isLoading: sectorLoading,
    error: sectorError,
  } = useGetSectorQuery(); // sector api call

  const { data: industryData, isLoading, isError } = useGetSubSectorQuery(); // industry api call
  const { data: districtList } = useGetDistrictQuery();
  const [getSingleMentor] = api.endpoints.singleMentor.useLazyQuery();
  const { data: specializationData } = useGetSpecializationQuery();
  const [createMentor] = useCreateMentorMutation();
  const [updMentor] = useUpdateMentorMutation();
  const role = localStorage.getItem("role").toLowerCase();

  const {
    data: startupStagesData,
    error: startupStagesError,
    isLoading: startupStagesLoading,
  } = useGetStartUpStageQuery(); // Startup stages api call
  const [sendToHOD] = useChangeStatusByHODMutation();

  async function generatePDF() {
    try {
      const { data } = await getSingleMentor(id);
      const {
        name,
        profile_pic,
        current_organization,
        current_designation,
        email,
        mobile,
        city,
        country,
        district,
        want_to_be_mentor,
        hour_dedicate_per_week,
        mentorship_provide_startup,
        success_stories,
        sector,
        industry,
        industry_sector,
        specialization,
        specialization_sector,
        startup_stage,
        linkedin,
        resume,
        blog_website,
        short_description,
        experience,
      } = data?.data;
      const doc = new jsPDF();
      doc.setFontSize(14);
      doc.text("Mentor Details", 10, 10);

      doc.setFontSize(10);
      doc.text(`Name: ${name || "N/A"}`, 10, 30);
      doc.text(
        `Current Organization: ${current_organization || "N/A"}`,
        10,
        40
      );
      doc.text(`Current Designation: ${current_designation || "N/A"}`, 10, 50);
      doc.text(`Email: ${email || "N/A"}`, 10, 60);
      doc.text(`Mobile: ${mobile || "N/A"}`, 10, 70);
      doc.text(`City: ${city || "N/A"}`, 10, 80);
      doc.text(`Country: ${country || "N/A"}`, 10, 90);
      doc.text(
        `Want to be Mentor: ${want_to_be_mentor ? "Yes" : "No"}`,
        10,
        100
      );
      doc.text(
        `Hours Dedicated Per Week: ${hour_dedicate_per_week || "N/A"}`,
        10,
        110
      );
      doc.text(
        `Mentorship Provide to Startup: ${mentorship_provide_startup || "N/A"}`,
        10,
        120
      );
      doc.text(`Blog Website: ${blog_website || "N/A"}`, 10, 130);
      doc.text(`Short Description: ${short_description || "N/A"}`, 10, 140);
      doc.text(`Experience: ${experience || "N/A"}`, 10, 150);

      // Save the PDF
      doc.save("mentor.pdf");
    } catch (error) {
      console.log("🚀 ~ generatePDF ~ error:", error);
    }
  }

  return (
    <Box marginTop={"6em"} padding={4}>
      <ToastContainer />
      <Paper className="paper-card">
        <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
          <Typography
            variant="h6"
            align="left"
            sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
          >
            Mentor Tracking Status
          </Typography>
          <TrackMentorApplication id={id} />
        </Grid>
        <Divider orientation={"horizontal"} sx={commonColor?.styles} />
        <Box textAlign="right">
          <Tooltip title="Download PDF" arrow>
            <IconButton sx={{ mt: 1 }} onClick={generatePDF} color="primary">
              <DownloadIcon sx={{ width: 30, height: 30 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          //   onSubmit={async (values) => {
          //     try {
          //       if (values.terms) {
          //         let data = {
          //           mentor_id:
          //             localStorage.getItem("user_id") ||
          //             "660a62f8011c670e90fd82fc",
          //           name: values.fullName,
          //           profile_pic: values.profilePic,
          //           current_organization: values.currentOrganization,
          //           current_designation: values.currentDesignation,
          //           email: values.email,
          //           mobile: values.mobile,
          //           city: values.city,
          //           experience: values.total_experience,
          //           district: values.district,
          //           want_to_be_mentor: values.purpose,
          //           hour_dedicate_per_week: values.hours,
          //           mentorship_provide_startup: values.mentorshipsProvided,
          //           success_stories: JSON.stringify(values.success_stories),
          //           sector: values.sector,
          //           industry: JSON.stringify(values.industries),
          //           industry_sector: JSON.stringify(values.industry_fields),
          //           specialization: JSON.stringify(values.specialization),
          //           specialization_sector: JSON.stringify(
          //             values.specialization_fields
          //           ),
          //           startup_stage: values.startupStage,
          //           linkedin: values.linkedin,
          //           resume: values.resume,
          //           blog_website: values.blog,
          //           short_description: values.description,
          //         };
          //         const formData = new FormData();

          //         // Append each key-value pair to the FormData object
          //         Object.keys(data).forEach((key) => {
          //           formData.append(key, data[key]);
          //         });

          //         if (id) {
          //           const response = await updMentor({
          //             id: id,
          //             payload: formData,
          //           });
          //           if (response?.data?.success) {
          //             toast.success(
          //               "Mentor Registration is Updated Successfully",
          //               {
          //                 position: "top-center",
          //                 autoClose: 2000,
          //                 hideProgressBar: false,
          //                 closeOnClick: true,
          //                 pauseOnHover: true,
          //                 draggable: true,
          //                 progress: undefined,
          //                 theme: "light",
          //               }
          //             );
          //             setTimeout(() => {
          //               navigate("/mentor-register");
          //               // setActive((prevActive) => prevActive + 1);
          //             }, 3000);
          //           } else if (response?.error) {
          //             toast.error(response?.error?.data?.message, {
          //               position: "top-center",
          //               autoClose: 3000,
          //               hideProgressBar: false,
          //               closeOnClick: true,
          //               pauseOnHover: true,
          //               draggable: true,
          //               progress: undefined,
          //               theme: "light",
          //             });
          //           }
          //         } else {
          //           const response = await createMentor(formData);
          //           if (response?.data?.success) {
          //             toast.success(
          //               "Mentor Registration is Created Sucessfully",
          //               {
          //                 position: "top-center",
          //                 autoClose: 2000,
          //                 hideProgressBar: false,
          //                 closeOnClick: true,
          //                 pauseOnHover: true,
          //                 draggable: true,
          //                 progress: undefined,
          //                 theme: "light",
          //               }
          //             );
          //             setTimeout(() => {
          //               navigate("/mentor-register");
          //             }, 3000);
          //           } else if (response?.error) {
          //             toast.error(response?.error?.data?.message, {
          //               position: "top-center",
          //               autoClose: 3000,
          //               hideProgressBar: false,
          //               closeOnClick: true,
          //               pauseOnHover: true,
          //               draggable: true,
          //               progress: undefined,
          //               theme: "light",
          //             });
          //           }
          //         }
          //       }
          //     } catch (error) {
          //       console.error("Error submitting data:", error);
          //     } finally {
          //       // setLoading(false);
          //     }
          //   }}
        >
          {({ setFieldValue, setValues, values, errors, touched }) => {
            // const handleChange = async (index, fieldName, value) => {
            //   const updatedCustomFields = [...values.industry_fields];
            //   updatedCustomFields[index][fieldName] = value;
            //   setFieldValue("industry_fields", updatedCustomFields);
            // };
            // const handleIncreaseFields = () => {
            //   setFieldValue("fieldsCount", values.fieldsCount + 1);
            //   setFieldValue("industry_fields", [
            //     ...values.industry_fields,
            //     { industry_sector: "" }, // Add an empty set of fields
            //   ]);
            // };

            // const handleDecreaseFields = () => {
            //   if (values.fieldsCount > 1) {
            //     setFieldValue("fieldsCount", values.fieldsCount - 1);
            //     setFieldValue(
            //       "industry_fields",
            //       values.industry_fields.slice(0, -1)
            //     ); // Remove the last set of fields
            //   }
            // };
            // //=========================>specialization handler's
            // const handleChangeSpecial = async (index, fieldName, value) => {
            //   const updatedCustomFields = [...values.specialization_fields];
            //   updatedCustomFields[index][fieldName] = value;
            //   setFieldValue("specialization_fields", updatedCustomFields);
            // };
            // const handleIncreaseFieldsSpecial = () => {
            //   setFieldValue("inputCount", values.inputCount + 1);
            //   setFieldValue("specialization_fields", [
            //     ...values.specialization_fields,
            //     { specialization_sector: "" }, // Add an empty set of fields
            //   ]);
            // };

            // const handleDecreaseFieldsSpecial = () => {
            //   if (values.fieldsCount > 1) {
            //     setFieldValue("inputCount", values.inputCount - 1);
            //     setFieldValue(
            //       "specialization_fields",
            //       values.specialization_fields.slice(0, -1)
            //     ); // Remove the last set of fields
            //   }
            // };

            // const handleChangeSuccess = async (index, fieldName, value) => {
            //   const updatedCustomFields = [...values.success_stories];
            //   updatedCustomFields[index][fieldName] = value;
            //   setFieldValue("startup_name", updatedCustomFields);
            // };
            // const handleIncreaseFieldsSuccess = () => {
            //   setFieldValue("successCount", values.successCount + 1);
            //   setFieldValue("success_stories", [
            //     ...values.success_stories,
            //     { startup_name: "" }, // Add an empty set of fields
            //   ]);
            // };

            // const handleDecreaseFieldsSuccess = () => {
            //   if (values.successCount > 1) {
            //     setFieldValue("successCount", values.successCount - 1);
            //     setFieldValue(
            //       "success_stories",
            //       values.success_stories.slice(0, -1)
            //     ); // Remove the last set of fields
            //   }
            // };
            const handleHODSection = async (e) => {
              e.preventDefault();
              try {
                console.log(
                  "🚀 ~ handleHODSection ~ values.status:",
                  errors.status,
                  errors.remarks
                );
                if (errors?.status && errors?.remarks) {
                  return;
                } else {
                  let data = {
                    id,
                    payload: {
                      status: values.status,
                      remarks: values.remarks,
                    },
                  };

                  const response = await sendToHOD(data);
                  if (response?.data?.success) {
                    toast.success(response.data.message, {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    setTimeout(() => {
                      navigate("/mentor-register");
                    }, 1000);
                  } else if (response?.error) {
                    toast.error(response?.error?.data?.message, {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                }
              } catch (error) {
                console.error("Error submitting data:", error);
              } finally {
              }
            };

            return (
              <Form>
                <UpdateValues id={id} />
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height: "20%",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: 1,
                    marginBottom: "2rem",
                  }}
                >
                  <Grid item md={6} lg={6} sm={6}>
                    <Typography variant="h5" align="center" fontWeight="600">
                      Mentor Preview
                    </Typography>
                  </Grid>
                  <Grid item md={6} lg={6} sm={6}>
                    <CustomizedProgressBars val={50} />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Mentor Details
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>Full Name</InputLabel>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="fullName"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.fullName}
                      onChange={(e) =>
                        setFieldValue("fullName", e.target.value)
                      }
                      error={touched.fullName && Boolean(errors.fullName)}
                      helperText={touched.fullName && errors.fullName}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    spacing={2}
                    display={"flex"}
                    justifyContent={"space-evenly"}
                  >
                    <Grid>
                      <InputLabel>Profile Picture</InputLabel>
                    </Grid>
                    <Grid>
                      {values?.profilePic && (
                        <Link
                          to={
                            !id
                              ? URL.createObjectURL(values?.profilePic)
                              : values?.profilePic
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            title="View Document"
                            variant="contained"
                            size="large"
                          >
                            <VisibilityIcon color="inherit" />
                          </Button>
                        </Link>
                      )}
                    </Grid>
                    {/* <TextField
                      InputProps={{
                      readOnly: true,
                    }}
                      name="resume"
                      type="file"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      inputProps={{ accept: "application/pdf"  }} // Accept only PDF files
                      onChange={(e) =>
                        setFieldValue("resume", e.target.files[0])
                      }
                      error={touched.resume && Boolean(errors.resume)}
                      helperText={touched.resume && errors.resume}
                    /> */}
                  </Grid>
                  {/* <Grid item xs={5}>
                    <InputLabel>Profile Picture</InputLabel>
                    <TextField
                      InputProps={{
                      readOnly: true,
                    }}
                      name="profilePic"
                      type="file"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      inputProps={{ accept: "image/*" }} // Accept only image files
                      onChange={(e) =>
                        setFieldValue("profilePic", e.target.files[0])
                      }
                      error={touched.profilePic && Boolean(errors.profilePic)}
                      helperText={touched.profilePic && errors.profilePic}
                    />
                  </Grid>
                  <Grid item xs={1} mt={6}>
                    {values?.profilePic && (
                      <Link
                        to={
                          !id
                            ? URL.createObjectURL(values?.profilePic)
                            : values?.profilePic
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <VisibilityIcon />
                      </Link>
                    )}
                  </Grid> */}
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="currentOrganization"
                      label="Current Organization"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.currentOrganization}
                      onChange={(e) =>
                        setFieldValue("currentOrganization", e.target.value)
                      }
                      error={
                        touched.currentOrganization &&
                        Boolean(errors.currentOrganization)
                      }
                      helperText={
                        touched.currentOrganization &&
                        errors.currentOrganization
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="currentDesignation"
                      label="Current Designation"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.currentDesignation}
                      onChange={(e) =>
                        setFieldValue("currentDesignation", e.target.value)
                      }
                      error={
                        touched.currentDesignation &&
                        Boolean(errors.currentDesignation)
                      }
                      helperText={
                        touched.currentDesignation && errors.currentDesignation
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="experience"
                      type="number"
                      required
                      inputProps={{ min: 0 }}
                      label="Total Experience"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.total_experience}
                      onChange={(e) =>
                        setFieldValue("total_experience", e.target.value)
                      }
                      error={
                        touched.total_experience &&
                        Boolean(errors.total_experience)
                      }
                      helperText={
                        touched.total_experience && errors.total_experience
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.email}
                      onChange={(e) => setFieldValue("email", e.target.value)}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="mobile"
                      label="Mobile"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.mobile}
                      onChange={(e) => setFieldValue("mobile", e.target.value)}
                      error={touched.mobile && Boolean(errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="city"
                      label="City"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.city}
                      onChange={(e) => setFieldValue("city", e.target.value)}
                      error={touched.city && Boolean(errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                      <TextField
                      InputProps={{
                      readOnly: true,
                    }}
                        name="state"
                        label="State"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={values.state}
                        onChange={(e) => setFieldValue("state", e.target.value)}
                        error={touched.state && Boolean(errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    </Grid> */}
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="district"
                      select
                      label="District"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.district}
                      onChange={(e) =>
                        setFieldValue("district", e.target.value)
                      }
                      error={touched.district && Boolean(errors.district)}
                      helperText={touched.district && errors.district}
                    >
                      {districtList?.data?.map((item) => (
                        <MenuItem value={item._id} key={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="country"
                      label="Country"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.country}
                      onChange={(e) => setFieldValue("country", e.target.value)}
                      error={touched.country && Boolean(errors.country)}
                      helperText={touched.country && errors.country}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <hr style={{ margin: "10px 0" }} />{" "}
                    {/* <hr> tag for horizontal line */}
                    <Typography variant="h6" gutterBottom>
                      Mentorship Requirements
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="purpose"
                      label="Purpose"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.purpose}
                      onChange={(e) => setFieldValue("purpose", e.target.value)}
                      error={touched.purpose && Boolean(errors.purpose)}
                      helperText={touched.purpose && errors.purpose}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="hours"
                      label="Number of Hours"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.hours}
                      onChange={(e) => setFieldValue("hours", e.target.value)}
                      error={touched.hours && Boolean(errors.hours)}
                      helperText={touched.hours && errors.hours}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="mentorshipsProvided"
                      label="Mentorships Provided"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.mentorshipsProvided}
                      onChange={(e) =>
                        setFieldValue("mentorshipsProvided", e.target.value)
                      }
                      error={
                        touched.mentorshipsProvided &&
                        Boolean(errors.mentorshipsProvided)
                      }
                      helperText={
                        touched.mentorshipsProvided &&
                        errors.mentorshipsProvided
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {[...Array(values.successCount)].map((_, index) => (
                      <React.Fragment key={index}>
                        <Grid item xl={10} lg={10} md={10} sm={6} xs={6}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            margin="normal"
                            fullWidth
                            required
                            id={`field-${index}`}
                            label="Startup Name"
                            name={`field-${index}`}
                            placeholder="Startup Name"
                            autoComplete={`startup_name-${index}`}
                            value={values?.success_stories[index]?.startup_name}
                            // onChange={(e) =>
                            //   handleChangeSuccess(
                            //     index,
                            //     "startup_name",
                            //     e.target.value
                            //   )
                            // }
                            // onBlur={}
                            error={
                              touched.success_stories && // Check if success_stories array has been touched
                              touched.success_stories[index] && // Check if this specific field has been touched
                              errors.success_stories && // Check if there are errors in success_stories array
                              errors.success_stories[index]?.startup_name // Check if there's an error for this specific field
                            }
                            helperText={
                              touched.success_stories && // Check if success_stories array has been touched
                              touched.success_stories[index] && // Check if this specific field has been touched
                              errors.success_stories && // Check if there are errors in success_stories array
                              errors.success_stories[index]?.startup_name // Show error message for this specific field
                            }
                          />
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
                          <IconButton
                            color="primary"
                            // onClick={() => handleIncreaseFieldsSuccess(index)}
                          >
                            <AddCircleOutline />
                          </IconButton>
                          {values.success_stories.length > 1 && (
                            <IconButton
                              color="secondary"
                              //   onClick={() => handleDecreaseFieldsSuccess(index)}
                            >
                              <RemoveCircleOutline />
                            </IconButton>
                          )}
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel>Sector</InputLabel>
                      <Field
                        label="Sector"
                        readOnly={true}
                        name="sector"
                        as={Select}
                        error={touched.sector && Boolean(errors.sector)}
                      >
                        {sectorData?.data.map((item) => (
                          <MenuItem value={item._id} key={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Field>
                      <FormHelperText>
                        <ErrorMessage name="sector" />
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="industries"
                      label="Industries"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      select
                      SelectProps={{
                        multiple: true,
                        renderValue: (selected) =>
                          Array.isArray(selected) &&
                          selected
                            ?.map((id) => {
                              const selectedItem = industryData?.data?.find(
                                (item) => item._id === id
                              );
                              return selectedItem ? selectedItem.name : "";
                            })
                            .join(", "),
                        MenuProps: {
                          onChange: (event) => {
                            const { value } = event.target;
                            const ids = value?.map((name) => {
                              const selectedItem = industryData?.data.find(
                                (item) => item.name === name
                              );
                              return selectedItem ? selectedItem._id : "";
                            });
                            setFieldValue("industries", ids);
                          },
                        },
                      }}
                      value={values.industries}
                      onChange={(e) => {
                        const selectedIds = e.target.value;
                        console.log("Selected Industry IDs:", selectedIds);
                        setFieldValue("industries", selectedIds);
                      }}
                      error={touched.industries && Boolean(errors.industries)}
                      helperText={touched.industries && errors.industries}
                    >
                      {industryData?.data.map((item) => (
                        <MenuItem value={item._id} key={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    {[...Array(values.fieldsCount)].map((_, index) => (
                      <React.Fragment key={index}>
                        <Grid item xl={10} lg={10} md={10} sm={6} xs={6}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            margin="normal"
                            fullWidth
                            required
                            id={`field-${index}`}
                            label="Industry Sector"
                            name={`field-${index}`}
                            placeholder="Industry Sector"
                            autoComplete={`industry_sector-${index}`}
                            value={
                              values?.industry_fields[index]?.industry_sector
                            }
                            // onChange={(e) =>
                            //   handleChange(
                            //     index,
                            //     "industry_sector",
                            //     e.target.value
                            //   )
                            // }
                            // onBlur={}
                            error={
                              touched.industry_fields && // Check if industry_fields array has been touched
                              touched.industry_fields[index] && // Check if this specific field has been touched
                              errors.industry_fields && // Check if there are errors in industry_fields array
                              errors.industry_fields[index]?.industry_sector // Check if there's an error for this specific field
                            }
                            helperText={
                              touched.industry_fields && // Check if industry_fields array has been touched
                              touched.industry_fields[index] && // Check if this specific field has been touched
                              errors.industry_fields && // Check if there are errors in industry_fields array
                              errors.industry_fields[index]?.industry_sector // Show error message for this specific field
                            }
                          />
                        </Grid>

                        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
                          <IconButton
                            color="primary"
                            // onClick={() => handleIncreaseFields(index)}
                          >
                            <AddCircleOutline />
                          </IconButton>
                          {values.industry_fields.length > 1 && (
                            <IconButton
                              color="secondary"
                              //   onClick={() => handleDecreaseFields(index)}
                            >
                              <RemoveCircleOutline />
                            </IconButton>
                          )}
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                  <Grid item xs={6}>
                    {[...Array(values.inputCount)].map((_, index) => (
                      <React.Fragment key={index}>
                        <Grid item xl={10} lg={10} md={10} sm={6} xs={6}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            margin="normal"
                            fullWidth
                            required
                            id={`field-${index}`}
                            label="Specialization Sector"
                            name={`field-${index}`}
                            placeholder="Specialization Sector"
                            autoComplete={`specialization_sector-${index}`}
                            value={
                              values?.specialization_fields[index]
                                ?.specialization_sector
                            }
                            // onChange={(e) =>
                            //   handleChangeSpecial(
                            //     index,
                            //     "specialization_sector",
                            //     e.target.value
                            //   )
                            // }
                            // onBlur={}
                            error={
                              touched.specialization_fields && // Check if specialization_fields array has been touched
                              touched.specialization_fields[index] && // Check if this specific field has been touched
                              errors.specialization_fields && // Check if there are errors in specialization_fields array
                              errors.specialization_fields[index]
                                ?.specialization_sector // Check if there's an error for this specific field
                            }
                            helperText={
                              touched.specialization_fields && // Check if specialization_fields array has been touched
                              touched.specialization_fields[index] && // Check if this specific field has been touched
                              errors.specialization_fields && // Check if there are errors in specialization_fields array
                              errors.specialization_fields[index]
                                ?.specialization_sector // Show error message for this specific field
                            }
                          />
                        </Grid>

                        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
                          <IconButton
                            color="primary"
                            // onClick={() => handleIncreaseFieldsSpecial(index)}
                          >
                            <AddCircleOutline />
                          </IconButton>
                          {values.specialization_fields.length > 1 && (
                            <IconButton
                              color="secondary"
                              //   onClick={() => handleDecreaseFieldsSpecial(index)}
                            >
                              <RemoveCircleOutline />
                            </IconButton>
                          )}
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="specialization"
                      label="Specialization"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      select
                      SelectProps={{
                        multiple: true,
                        renderValue: (selected) =>
                          selected
                            .map(
                              (value) =>
                                specializationData?.data?.find(
                                  (item) => item._id === value
                                )?.name
                            )
                            .join(", "),
                      }}
                      value={values.specialization}
                      onChange={(e) => {
                        const value = e.target.value;
                        console.log("Selected Specializations:", value);
                        setFieldValue("specialization", value);
                      }}
                      error={
                        touched.specialization && Boolean(errors.specialization)
                      }
                      helperText={
                        touched.specialization && errors.specialization
                      }
                    >
                      {specializationData?.data?.map((item) => (
                        <MenuItem value={item._id} key={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel>Startup Stage</InputLabel>
                      <Field
                        label="Startup Stage"
                        readOnly={true}
                        name="startupStage"
                        as={Select}
                        error={
                          touched.startupStage && Boolean(errors.startupStage)
                        }
                      >
                        {startupStagesData?.data?.map((item) => (
                          <MenuItem value={item._id} key={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Field>
                      <FormHelperText>
                        <ErrorMessage name="startupStage" />
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>LinkedIn URL</InputLabel>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="linkedin"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.linkedin}
                      onChange={(e) =>
                        setFieldValue("linkedin", e.target.value)
                      }
                      error={touched.linkedin && Boolean(errors.linkedin)}
                      helperText={touched.linkedin && errors.linkedin}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    spacing={2}
                    display={"flex"}
                    justifyContent={"space-evenly"}
                  >
                    <Grid>
                      <InputLabel>Resume</InputLabel>
                    </Grid>
                    <Grid>
                      {values?.resume && (
                        <Link
                          to={
                            !id
                              ? URL.createObjectURL(values?.resume)
                              : values?.resume
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            title="View Document"
                            variant="contained"
                            size="large"
                          >
                            <VisibilityIcon color="inherit" />
                          </Button>
                        </Link>
                      )}
                    </Grid>
                    {/* <TextField
                      InputProps={{
                      readOnly: true,
                    }}
                      name="resume"
                      type="file"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      inputProps={{ accept: "application/pdf"  }} // Accept only PDF files
                      onChange={(e) =>
                        setFieldValue("resume", e.target.files[0])
                      }
                      error={touched.resume && Boolean(errors.resume)}
                      helperText={touched.resume && errors.resume}
                    /> */}
                  </Grid>
                  {/* <Grid item xs={1} mt={6}>
                    
                  </Grid> */}
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="description"
                      label="Description"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.description}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      name="blog"
                      label="Blog"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.blog}
                      onChange={(e) => setFieldValue("blog", e.target.value)}
                      error={touched.blog && Boolean(errors.blog)}
                      helperText={touched.blog && errors.blog}
                    />
                  </Grid>
                </Grid>
                {role === "hod" || role === "superadmin" || role === "admin" ? (
                  <>
                    <Grid
                      item
                      md={6}
                      lg={6}
                      xl={6}
                      xs={12}
                      sx={{ marginTop: 2 }}
                    >
                      <Divider
                        orientation={"horizontal"}
                        sx={commonColor?.styles}
                      />

                      <Typography
                        variant="h6"
                        align="left"
                        sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
                      >
                        HOD Section
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      mt={2}
                    >
                      <Grid item md={6} lg={6} xl={6} xs={12}>
                        <Typography
                          variant="h6"
                          align="left"
                          sx={{ fontWeight: 400 }}
                        >
                          Status:
                        </Typography>
                        <FormControl fullWidth sx={{ marginTop: 2 }}>
                          <InputLabel id="Select Status">Status</InputLabel>
                          <Select
                            labelId="Select Status"
                            required
                            fullWidth
                            id="status"
                            label="Select Status"
                            name="status"
                            autoComplete="off"
                            placeholder="Status"
                            value={values.status}
                            onChange={(e) =>
                              setFieldValue("status", e.target.value)
                            }
                            error={ Boolean(errors.status)}
                            // helperText={touched.status && errors.status}

                            // onBlur={() => setFieldTouched("status", true)}
                          >
                            <MenuItem value="">--Select Status--</MenuItem>
                            <MenuItem value="rejected">Reject</MenuItem>
                            <MenuItem value="approved">Approve</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} lg={6} xl={6} xs={12} mt={1}>
                        <Typography
                          variant="h6"
                          align="left"
                          sx={{ fontWeight: 400 }}
                        >
                          Remarks:
                        </Typography>
                        <TextField
                          label="Remarks"
                          required
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name="remarks"
                          id="remarks"
                          value={values.remarks}
                          onChange={(e) =>
                            setFieldValue("remarks", e.target.value)
                          }
                          error={  errors.remarks && Boolean(errors.remarks)}
                          helperText={  errors.remarks? errors.remarks:null}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="button-right"
                    >
                      {/* <Button
              type="button"
              variant="contained"
              onClick={()=>{navigate('/application-status')}}
              // aria-readonly={active === 0}
            >
              Previous
            </Button> */}
                      <Box ml={2} display="inline-block">
                        <LoadingButton
                          // type="submit"
                          variant="contained"
                          onClick={(e) => {
                            handleHODSection(e);
                          }}
                          // aria-readonly={active === steps.length - 1}
                          endIcon={<SendIcon />}
                          // loading={loading}
                          loadingPosition="end"
                          size="small"
                        >
                          <span>Submit</span>
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </>
                ) : null}
                <ToastContainer /> {/* Toast container for notifications */}
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </Box>
  );
};

export default MentorPreview;
