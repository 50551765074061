import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Paper,
  IconButton,
  Switch,
  Stack,
  Chip,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import {
  api,
  useAllApplicantQuery,
  useBarChartLogsQuery,
  useDeleteApplicantMutation,
  useGetApplicationQuery,
  useGetDistrictQuery,
  useGetMentorQuery,
  useGetPlatformQuery,
  useGetRecognitionQuery,
  useIncubationDetailsQuery,
  useResourceListQuery,
} from "../../services/api";
import InfoIcon from "@mui/icons-material/Info";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import FlexBetween from "../../Components/FlexBetween";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ToastContainer } from "react-toastify";
import { Delete, Edit } from "@mui/icons-material/";
import moment from "moment";
import swal from "sweetalert";
import styled from "styled-components";
import StackedBarChart from "../BarChat";

const Dashboard = () => {
  const role = localStorage.getItem("role")?.toLowerCase();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [appDistt, setAppDist] = useState("");
  const [recoDistt, setRecoDistt] = useState("");
  const { data: allApplicant, isError } = useGetApplicationQuery({
    data: appDistt ? `${appDistt}` : null,
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
  });
  const { data: recognitionList, isLoading } = useGetRecognitionQuery({
    data: recoDistt ? `${recoDistt}` : null,
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
  });
  const { data: incubationUser } = useIncubationDetailsQuery();
  const [applicantDelete] = useDeleteApplicantMutation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [assembly, setAssembly] = useState([]);
  const [source, setSource] = useState("");
  // const [lacData] = api.endpoints.getLegislativeDistrictWise.useLazyQuery();
  // const [resourceData] = api.endpoints.resourceList.useLazyQuery();
  const district_id = localStorage.getItem("user_district");
  const { data: platformList } = useGetPlatformQuery();
  const { data: resourceList } = useResourceListQuery({
    data: source ? source : null,
  });
  const { data: districtList } = useGetDistrictQuery();

  const { data: mentorList } = useGetMentorQuery({
    data: role === "dic" ? "mentor_list" : "all_list",
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
  });

  // const lacDeatilsData = async () => {
  //   if(district_id===null)return;
  //   if(district_id!==null){
  //     console.log('first')
  //     const lacArrData = await lacData(district_id);
  //     setAssembly(lacArrData?.data);
  //   }
  // };
  // useEffect(() => {
  //   lacDeatilsData();
  // }, [district_id]);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want delete the Application!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        applicantDelete(id);
        swal("Application is deleted Successfully.!", {
          icon: "success",
        });
      } else {
        swal("Application is safe!");
      }
    });
  };
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  }));
  const handleEdit = (_id) => {
    localStorage.setItem("application_id", _id);
    navigate(`/applicant`);
  };

  const handleMentor = (id) => {
    navigate(`/select-mentor/${id}`);
  };

  const handleClickOpen = (id) => {
    navigate(`/progress-report/${id}`);
  };

  const getActionsWrapper = ({ params = null, role = null }) => {
    const actions = [];
    if (!params?.row?.finalSubmit && role === "user") {
      actions.push(
        <GridActionsCellItem
          color="success"
          icon={<Edit />}
          onClick={() => {
            handleEdit(params.row._id);
          }}
          label="Edit"
        />,
        <GridActionsCellItem
          color="primary"
          icon={<VisibilityIcon />}
          onClick={() => navigate(`/applicant/preview/${params?.id}`)}
          label="View"
        />
      );
    } else if (
      params?.row?.finalSubmit &&
      role === "user" &&
      params?.row?.status === "selected" &&
      params?.row?.mantor_id === null
    ) {
      actions.push(
        <GridActionsCellItem
          color="primary"
          icon={<VisibilityIcon />}
          onClick={() => navigate(`/applicant/preview/${params?.id}`)}
          label="View"
        />,
        <Button
          onClick={() => {
            handleMentor(params.row._id);
          }}
          title="Select Mentor"
          variant="contained"
          size="small"
        >
          Select Mentor
        </Button>
      );
    } else if (
      role === "user" &&
      params?.row?.status === "selected" &&
      params?.row?.mantor_id
    ) {
      actions.push(
        <GridActionsCellItem
          color="primary"
          icon={<VisibilityIcon />}
          onClick={() => navigate(`/applicant/preview/${params?.id}`)}
          label="View"
        />,
        <Button
          onClick={() => {
            handleClickOpen(params.row._id);
          }}
          title="Change Status"
          variant="contained"
          size="small"
        >
          Generate PR
        </Button>
      );
    } else if (params?.row?.finalSubmit && role === "incubation") {
      actions.push(
        // <GridActionsCellItem
        //   icon={<VisibilityIcon />}
        //   onClick={() => navigate(`/applicant/preview/${params?.id}`)}
        //   label="View"
        // />,
        <Button
          onClick={() => navigate(`/applicant/preview/${params?.id}`)}
          title="View PR"
          variant="contained"
          size="small"
        >
          View Application
        </Button>,
        <Button
          onClick={() => navigate(`/progress-report/${params?.id}`)}
          title="View PR"
          variant="contained"
          size="small"
        >
          View PR
        </Button>
      );
    } else if (
      params?.row?.finalSubmit &&
      (role === "superadmin" || role === "admin" || role === "hod")
    ) {
      actions.push(
        <GridActionsCellItem
          color="success"
          icon={<Edit />}
          onClick={() => {
            handleEdit(params.row._id);
          }}
          title="Edit Application"
          label="Edit"
        />,
        <GridActionsCellItem
          color="error"
          icon={<Delete />}
          onClick={() => {
            handleDelete(params.row._id);
          }}
          label="Delete"
          title="Delete Application"
        />,
        <Button
          onClick={() => navigate(`/applicant/preview/${params?.id}`)}
          title="View Application"
          variant="contained"
          size="small"
        >
          View Application
        </Button>
        // <GridActionsCellItem
        //   icon={<VisibilityIcon />}
        //   onClick={() => navigate(`/applicant/preview/${params?.id}`)}
        //   label="View"
        // />
      );
    }
    return actions;
  };

  const columns = useMemo(
    () => [
      {
        field: "founder_name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "category",
        headerName: "Category",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "contact",
        headerName: "Mobile Number",
        flex: 1,
      },
      // {
      //   field: "actions",
      //   headerName: "Actions",
      //   type: "actions",
      //   flex: 1,
      //   getActions: (params) => getActionsWrapper({ params, role }),
      // },
    ],
    [role]
  );
  const columns1 = useMemo(
    () => [
      {
        field: "founder_name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "category",
        headerName: "Category",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "contact",
        headerName: "Mobile Number",
        flex: 1,
      },
      // {
      //   field: "actions",
      //   headerName: "Actions",
      //   type: "actions",
      //   flex: 1,
      //   getActions: (params) => getActionsWrapper({ params, role, loading }),
      // },
    ],
    [role, loading]
  );
  const columns2 = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      // {
      //   field: "district",
      //   headerName: "Category",
      //   flex: 1,
      //   renderCell: (params) => {
      //     return (
      //       <Typography>
      //         {params?.row?.district?.name}
      //       </Typography>
      //     );
      //   },
      // },

      {
        field: "documentStatus",
        headerName: "Status",
        renderCell: (params) => (
          <Switch
            value={params.row._id}
            checked={params.row.documentStatus === "active"}
            // onChange={() => {
            //   handleChange(params.row);
            // }}
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
        flex: 0.5,
      },
      // {
      //   field: "email",
      //   headerName: "Email",
      //   flex: 1,
      // },
      // {
      //   field: "contact",
      //   headerName: "Mobile Number",
      //   flex: 1,
      // },
      // {
      //   field: "actions",
      //   headerName: "Actions",
      //   type: "actions",
      //   flex: 1,
      //   getActions: (params) => getActionsWrapper({ params, role, loading }),
      // },
    ],
    []
  );
  const columns3 = useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "createdAt",
        headerName: "Date",
        flex: 1,
        renderCell: (params) => (
          <Typography>
            {moment(params?.row?.createdAt).format("DD/MM/YYYY")}
          </Typography>
        ),
      },
      // {
      //   field: "documentStatus",
      //   headerName: "Status",
      //   flex: 0.7,
      //   renderCell: (params) => (
      //     <Switch
      //       value={params.row._id}
      //       checked={params.row.documentStatus === "active"}
      //       // onChange={() => {
      //       //   handleChange(params.row);
      //       // }}
      //       inputProps={{ "aria-label": "controlled" }}
      //     />
      //   ),
      // },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        getActions: (params) => [
          // <GridActionsCellItem
          //   color="success"
          //   icon={<Edit />}
          //   label="Edit"
          // />,
          // <GridActionsCellItem
          //   color="error"
          //   icon={<Delete />}
          //   onClick={() => {
          //     handleDelete(params.row._id);
          //   }}
          //   label="Delete"
          // />,
        ],
      },
    ];
  }, []);
  const columns4 = useMemo(() => {
    return [
      {
        field: "country",
        headerName: "Country",
        flex: 1,
      },
      {
        field: "city",
        headerName: "City",
        flex: 1,
      },
      {
        field: "current_organization",
        headerName: "Organization",
        // renderCell: (params) => {
        //   return <Typography>{params?.row?.roleId?.name}</Typography>;
        // },
        flex: 1,
      },
      // {
      //   field: "actions",
      //   headerName: "Actions",
      //   type: "actions",
      //   getActions: (params) => getActionsWrapper({ params, role }),
      //   flex: 1,
      // },
    ];
  }, []);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPaginationModel((prev) => ({ ...prev, pageSize: newPageSize.pageSize }));
  }, []);
  const handlePaginationModelChange = useCallback((data) => {
    setPaginationModel(data);
  }, []);
  return (
    <Box marginTop={"6em"} padding={4}>
      <Box
        sx={{
          justifyContent: "space-between",
          flex: 1,
          width: "100%",
          backgroundColor: (theme) => theme.palette.default,
          color: (theme) => theme.palette.text.primary,
          borderRadius: "4px",
        }}
      >
        <ToastContainer />
        {/* Main content */}
        <Box sx={{ width: "100%" }}>
          {/* Main content */}
          {/* <Container sx={{ width: "100%", overflow: "auto" }}> */}
          <Grid container spacing={2}>
            {role !== "dic" ? (
              <Grid item xs={12} md={3}>
                <Card className="card-stats">
                  <CardContent className="card-stats-content">
                    <Box className="card-icon card-warning">
                      <TextSnippetIcon
                        className="card-ic"
                        sx={{ color: "#ffffff " }}
                      />
                    </Box>
                    <Typography className="card-stats-title">
                      Total Application
                    </Typography>
                    <Typography className="card-stats-items">
                      {allApplicant?.data?.totalItems}
                    </Typography>
                  </CardContent>

                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="card-stats-footer"
                  >
                    <Grid>
                      <Typography mt={1}>View</Typography>
                    </Grid>
                    <Grid>
                      <Tooltip title="Application Information">
                        <IconButton
                          onClick={() => navigate(`/application-status`)}
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : null}
            {role === "incubation" ||
            role === "superadmin" ||
            role == "hod" ||
            role === "admin" ||
            role === "dic" ? (
              <Grid item xs={12} md={3}>
                <Card className="card-stats">
                  <CardContent className="card-stats-content">
                    <Box className="card-icon card-rose">
                      <QueryBuilderIcon
                        className="card-ic"
                        sx={{ color: "#ffffff " }}
                      />
                    </Box>
                    <Typography className="card-stats-title">
                      Pending Application
                    </Typography>
                    <Typography className="card-stats-items">
                      {allApplicant?.data?.statusCounts?.pending}
                    </Typography>
                  </CardContent>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="card-stats-footer"
                  >
                    <Grid>
                      <Typography mt={1}>View</Typography>
                    </Grid>
                    <Grid>
                      <Tooltip title="Application Information">
                        <IconButton
                          onClick={() => navigate(`/application-status`)}
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : (
              <></>
            )}
            {role === "incubation" ||
            role === "superadmin" ||
            role == "hod" ||
            role === "admin" ||
            role == "dic" ? (
              <Grid item xs={12} md={3}>
                <Card className="card-stats">
                  <CardContent className="card-stats-content">
                    <Box className="card-icon card-success">
                      <CheckCircleOutlineIcon
                        className="card-ic"
                        sx={{ color: "#ffffff " }}
                      />
                    </Box>
                    <Typography className="card-stats-title">
                      Approved Application
                    </Typography>
                    <Typography className="card-stats-items">
                      {allApplicant?.data?.statusCounts?.approved}
                    </Typography>
                  </CardContent>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="card-stats-footer"
                  >
                    <Grid>
                      <Typography mt={1}>View</Typography>
                    </Grid>
                    <Grid>
                      <Tooltip title="Application Information">
                        <IconButton
                          onClick={() => navigate(`/application-status`)}
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : (
              <></>
            )}

            {role === "dic" ? (
              <Grid item xs={12} md={3}>
                <Card className="card-stats">
                  <CardContent className="card-stats-content">
                    <Box className="card-icon card-rose">
                      <QueryBuilderIcon
                        className="card-ic"
                        sx={{ color: "#ffffff " }}
                      />
                    </Box>
                    <Typography className="card-stats-title">
                      Pending Recognition Application
                    </Typography>
                    <Typography className="card-stats-items">
                      {allApplicant?.data?.statusCounts?.pending}
                    </Typography>
                  </CardContent>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="card-stats-footer"
                  >
                    <Grid>
                      <Typography mt={1}>View</Typography>
                    </Grid>
                    <Grid>
                      <Tooltip title="Application Information">
                        <IconButton
                          onClick={() => navigate(`/recognition`)}
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : (
              <></>
            )}
            {role == "dic" ? (
              <Grid item xs={12} md={3}>
                <Card className="card-stats">
                  <CardContent className="card-stats-content">
                    <Box className="card-icon card-success">
                      <CheckCircleOutlineIcon
                        className="card-ic"
                        sx={{ color: "#ffffff " }}
                      />
                    </Box>
                    <Typography className="card-stats-title">
                      Approved Recognition Application
                    </Typography>
                    <Typography className="card-stats-items">
                      {allApplicant?.data?.statusCounts?.approved}
                    </Typography>
                  </CardContent>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="card-stats-footer"
                  >
                    <Grid>
                      <Typography mt={1}>View</Typography>
                    </Grid>
                    <Grid>
                      <Tooltip title="Application Information">
                        <IconButton
                          onClick={() => navigate(`/recognition`)}
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : (
              <></>
            )}
            {role === "user" ? (
              <Grid item xs={12} md={3}>
                <Card className="card-stats">
                  <CardContent className="card-stats-content">
                    <Box className="card-icon card-success">
                      <InfoIcon
                        className="card-ic"
                        sx={{ color: "#ffffff " }}
                      />
                    </Box>
                    <Typography className="card-stats-title">
                      Last Submitted Application
                    </Typography>
                    <Typography>
                      {" "}
                      Date{" "}
                      {allApplicant?.data?.AllApplication[0]?.lastSubmissionDate
                        ? moment(
                            allApplicant?.data?.AllApplication[0]
                              ?.lastSubmissionDate
                          ).format("LL")
                        : null}
                    </Typography>
                  </CardContent>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="card-stats-footer"
                  >
                    <Grid>
                      <Typography mt={1}>View</Typography>
                    </Grid>
                    <Grid>
                      <Tooltip title="Application Information">
                        <IconButton
                          onClick={() => navigate(`/application-status`)}
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : (
              <></>
            )}

            {role === "superadmin" || role == "hod" || role === "admin" ? (
              <Grid item xs={12} md={3}>
                <Card className="card-stats">
                  <CardContent className="card-stats-content">
                    <Box className="card-icon card-info">
                      <EventRepeatIcon
                        className="card-ic"
                        sx={{ color: "#ffffff " }}
                      />
                    </Box>
                    <Typography className="card-stats-title">
                      Total Incubation
                    </Typography>
                    <Typography className="card-stats-items">
                      {incubationUser?.total}
                    </Typography>
                  </CardContent>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="card-stats-footer"
                  >
                    <Grid>
                      <Typography mt={1}>View</Typography>
                    </Grid>
                    <Grid>
                      <Tooltip title="Incubation Information">
                        <IconButton
                          onClick={() => navigate(`/incubation-center`)}
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Box>
        {role === "hod" || role === "dic" || role === "superadmin" ? (
           <Card
           sx={{
             marginBottom: 2,
             //  borderRadius: 2
           }}
         >
           <CardHeader
             sx={{
               backgroundColor: "#1976d2",
               color: "common.white",
             }}
             title={
               <Typography
                 variant="h6"
                 component="span"
                 sx={{ fontWeight: "bold" }}
               >
                 Live Data  
               </Typography>
             }
           />
           <CardContent className="paper-card">
             {/* Table goes here */}
          <StackedBarChart />
            
           </CardContent>
         </Card>
        ) : (
          <></>
        )}

        {/* Table */}
      </Box>

      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.default,
          color: (theme) => theme.palette.text.primary,
          borderRadius: 2,
        }}
      >
        <Box sx={{ width: "100%", marginTop: 1, borderRadius: 2 }}>
          <Card
            sx={{
              marginBottom: 2,
              //  borderRadius: 2
            }}
          >
            <CardHeader
              sx={{
                backgroundColor: "#1976d2",
                color: "common.white",
              }}
              title={
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ fontWeight: "bold" }}
                >
                  Applicant List
                </Typography>
              }
            />
            <CardContent className="paper-card">
              {/* Table goes here */}
              <Grid container>
                {role === "dic" ? (
                  <Grid item xl={5} lg={5} md={5} sm={12} xs={12} mt={3}>
                    {/* <InputLabel id="language_change" sx={{ color: "#000" }}>
                District :
              </InputLabel> */}
                    <FormControl fullWidth>
                      <InputLabel id="app_district">Select District</InputLabel>
                      <Select
                        margin="dense"
                        fullWidth
                        id="app_district"
                        label="Select District"
                        name="district"
                        value={appDistt}
                        onChange={(e) => {
                          e.preventDefault();
                          setAppDist(e.target.value);
                        }}
                        // onBlur={formik.handleBlur}
                        // error={
                        //   formik.touched.district && Boolean(formik.errors.district)
                        // }
                        // helperText={formik.touched.district && formik.errors.district}
                      >
                        {districtList?.data?.map((item) => (
                          <MenuItem value={item._id} key={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
              </Grid>
              <Box
                marginTop={2}
                sx={{ width: "100%", height: "500px",}}
              >
                <DataGrid
                  columns={columns}
                  rowHeight={40}
                  rowCount={allApplicant?.data?.totalItems || 0}
                  getRowId={(row) => row?._id}
                  pageSizeOptions={[10, 25, 50, 100]}
                  // loading={isLoading}
                  pagination
                  paginationModel={paginationModel}
                  paginationMode="server"
                  onPageSizeChange={handlePageSizeChange}
                  onPaginationModelChange={handlePaginationModelChange}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    toolbar: GridToolbar,
                  }}
                  sx={{
                    // "--DataGrid-overlayHeight": "100%",
                    width: "100%",
                    marginBottom: "16px",
                    // borderRadius: 2,
                  }}
                  rows={allApplicant?.data.AllApplication || []}
                />
              </Box>
            </CardContent>
          </Card>
          {role === "dic" ||
          role === "hod" ||
          role === "admin" ||
          role === "superadmin" ? (
            <Card
              sx={{
                marginBottom: 2,
                //  borderRadius: 2
              }}
            >
              <CardHeader
                sx={{
                  backgroundColor: "#1976d2",
                  color: "common.white",
                }}
                title={
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{ fontWeight: "bold" }}
                  >
                    Recognition List
                  </Typography>
                }
              />
              <CardContent className="paper-card">
                <Grid container>
                  {role === "dic" ? (
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12} mt={3}>
                      {/* <InputLabel id="language_change" sx={{ color: "#000" }}>
                District :
              </InputLabel> */}
                      <FormControl fullWidth>
                        <InputLabel id="reco_district">
                          Select District
                        </InputLabel>
                        <Select
                          margin="dense"
                          fullWidth
                          id="reco_district"
                          label="Select District"
                          name="district"
                          value={recoDistt}
                          onChange={(e) => {
                            e.preventDefault();
                            setRecoDistt(e.target.value);
                          }}
                          // onBlur={formik.handleBlur}
                          // error={
                          //   formik.touched.district && Boolean(formik.errors.district)
                          // }
                          // helperText={formik.touched.district && formik.errors.district}
                        >
                          {districtList?.data?.map((item) => (
                            <MenuItem value={item._id} key={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                </Grid>
                <Box
                  marginTop={2}
                  sx={{ width: "100%", height: "500px", overflowX: "auto" }}
                >
                  <DataGrid
                    columns={columns1}
                    // autoHeight
                    rowHeight={40} // Adjusted row height
                    // getRowHeight={() => 40} // Set the row height here
                    rowCount={recognitionList?.data?.totalItems || 0}
                    getRowId={(row) => row._id}
                    pageSizeOptions={[10, 25, 50, 100]}
                    loading={isLoading}
                    pagination
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPageSizeChange={handlePageSizeChange}
                    onPaginationModelChange={handlePaginationModelChange}
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                      toolbar: GridToolbar,
                    }}
                    sx={{
                      width: "100%",
                      marginBottom: "16px",
                      "& .MuiDataGrid-cell": {
                        py: 1, // Adjust padding of cells
                      },
                    }}
                    rows={recognitionList?.data?.AllApplication || []}
                  />
                </Box>
              </CardContent>
            </Card>
          ) : null}
          {/* { role==="dic"?<Card
            sx={{
              marginBottom: 2,
              //  borderRadius: 2
            }}
          >
            <CardHeader
              sx={{
                backgroundColor: "#1976d2",
                color: "common.white",
              }}
              title={
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ fontWeight: "bold" }}
                >
                  Constitency List
                </Typography>
              }
            />
            <CardContent></CardContent>
          
            <Box marginTop={2} width="100%">
              <DataGrid
                columns={columns2}
                getRowHeight={() => 40} // Set the row height here
                rowCount={assembly?.total || 0}
                getRowId={(row) => row._id}
                pageSizeOptions={[10, 25, 50, 100]}
                loading={isLoading}
                pagination
                paginationModel={paginationModel}
                paginationMode="server"
                onPageSizeChange={handlePageSizeChange}
                onPaginationModelChange={handlePaginationModelChange}
                slots={{
                  // noRowsOverlay: CustomNoRowsOverlay,
                  toolbar: GridToolbar,
                }}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  "& .MuiDataGrid-cell": {
                    py: 1, // Adjust padding of cells
                  },
                }}
                rows={assembly?.data || []}
              />
            </Box>
          </Card>:null} */}
          {role === "hod" || role === "admin" || role === "superadmin" ? (
            <Card
              sx={{
                marginBottom: 2,
                //  borderRadius: 2
              }}
            >
              <CardHeader
                sx={{
                  backgroundColor: "#1976d2",
                  color: "common.white",
                }}
                title={
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{ fontWeight: "bold" }}
                  >
                    Resource/Plateform List
                  </Typography>
                }
              />
              <Paper className="paper-card">
                <Grid container>
                  <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                    <FormControl fullWidth>
                      <InputLabel id="Resource">Resource/Platform</InputLabel>
                      <Select
                        margin="dense"
                        fullWidth
                        id="source"
                        label="Resource/Platform"
                        name="source"
                        value={source}
                        onChange={(e) => {
                          e.preventDefault();
                          setSource(e.target.value);
                        }}
                      >
                        {platformList?.data?.map((item) => (
                          <MenuItem value={item._id} key={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Box
                  marginTop={2}
                  sx={{ width: "100%", height: "500px", }}
                >
                  <DataGrid
                    columns={columns3}
                    rowHeight={40}
                    getRowId={(row) => row._id}
                    rowCount={resourceList?.total || 0}
                    pageSizeOptions={[10, 25, 50, 100]}
                    loading={isLoading}
                    pagination
                    columnBuffer={columns3.length + 1}
                    rowBuffer={10}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPageSizeChange={(newPageSize) =>
                      handlePageSizeChange(newPageSize)
                    }
                    onPaginationModelChange={(data) =>
                      handlePageSizeChange(data)
                    }
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                      toolbar: GridToolbar,
                    }}
                    sx={{
                      // "--DataGrid-overlayHeight": "100%",
                      width: "100%",
                      marginBottom: "16px",
                    }}
                    rows={resourceList?.data || []}
                  />
                </Box>
              </Paper>
            </Card>
          ) : null}
          {role === "dic" ||
          role === "hod" ||
          role === "admin" ||
          role === "superadmin" ? (
            <Card
              sx={{
                marginBottom: 2,
                //  borderRadius: 2
              }}
            >
              <CardHeader
                sx={{
                  backgroundColor: "#1976d2",
                  color: "common.white",
                }}
                title={
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{ fontWeight: "bold" }}
                  >
                    Mentor List
                  </Typography>
                }
              />
              <CardContent className="paper-card">
                <Box
                  marginTop={2}
                  sx={{ width: "100%", height: "400px", overflowX: "auto" }}
                >
                  <DataGrid
                    columns={columns4}
                    autoHeight
                    rowHeight={40}
                    getRowId={(row) => row._id}
                    rowCount={mentorList?.data?.totalItems || 0}
                    pageSizeOptions={[10, 25, 50, 100]}
                    loading={isLoading}
                    pagination
                    columnBuffer={columns4.length + 1}
                    rowBuffer={10}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPageSizeChange={(newPageSize) =>
                      handlePageSizeChange(newPageSize)
                    }
                    onPaginationModelChange={(data) =>
                      handlePageSizeChange(data)
                    }
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                      toolbar: GridToolbar,
                    }}
                    sx={{
                      // "--DataGrid-overlayHeight": "100%",
                      width: "100%",
                      marginBottom: "16px",
                    }}
                    rows={mentorList?.data?.getAllMentor || []}
                  />
                </Box>
              </CardContent>
            </Card>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
