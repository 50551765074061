import { Box, Grid, Typography, Container, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import { data } from "./constant";
import IconCardV2 from "../../Components/IconCard/indexv2";
import black_bg from "../../Assests/success-stories-bg.jpg";
import { ThemeContext } from "../../services/ThemeContext";

const EcoSystem2 = () => {
  const { t } = useTranslation();
  const theme=useTheme()
  const { fontSize } = React.useContext(ThemeContext);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        background: `url(${theme.palette.mode==="dark"?null:black_bg}) center center no-repeat`, // Center the background image
        backgroundSize: "cover",
        height: "100%",
        width: "100%",
        padding: "50px 0", // Equal padding
        justifyContent: "space-around",
         backgroundColor: (theme) => theme.palette.background.default,
          color: (theme) => theme.palette.text.primary,
        
      }}
    >
    <Container maxWidth="xl">

      <Grid container>
      <Grid item sx={{ marginBottom: 3 }}  lg={12} xs={12}>
         <Typography
            component="h1"
            sx={{
              fontWeight: 700,
              fontSize: `${fontSize}px`,
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            {t("success_stories")}
          </Typography>
          <CustomizedProgressBars className={"section_sep"} val={50} />
        </Grid>

        <Grid item lg={12} xs={12}>
        <IconCardV2
            data={data}
            borderTopLeftRadius="25px"
            borderBottomRightRadius="25px"
            borderColor="#79A4C8"
            borderWidth="2px"
            // margin="20px" // Equal margin
            mediaHeight="200px"
            width="auto"
            minHeight="70px"
          />
        </Grid>
      </Grid>
      </Container>
    </Box>

    
    // <Container>
    //   <Grid container alignItems="center" justifyContent="center">
    //     <Grid item>
    //       <Typography
    //         component="h1"
    //         sx={{
    //           fontWeight: 700,
    //           fontSize: "x-large",
    //           color: "#fff",
    //           textAlign: "center",
    //           color: "#000",
    //         }}
    //       >
    //         {t("success_stories")}
    //       </Typography>
    //       <CustomizedProgressBars val={50} />
    //     </Grid>
    //   </Grid>


      
    //   <Grid className="App_" sx={{ marginTop: 5 }}>
    //     <Grid
    //       style={{
    //         backgroundImage: `url(${bg})`,
    //         backgroundSize: "cover",
    //         backgroundPosition: "center",
    //         padding: 10,
    //       }}
    //     >
    //       <IconCardV2
    //         data={data}
    //         borderTopLeftRadius="25px"
    //         borderBottomRightRadius="25px"
    //         borderColor="#79A4C8"
    //         borderWidth="2px"
    //         // margin="20px" // Equal margin
    //         cardPadding="20px"
    //         mediaHeight="200px"
    //         width="auto"
    //         minHeight="70px"
    //       />
    //     </Grid>
    //   </Grid>
    // </Container>
  );
};

export default EcoSystem2;
