import React from 'react';
import { CircularProgress, Container, Grid } from "@mui/material";

// Import your loader GIF
import loaderGif from '../Assests/loader.gif'; // Update the path according to your project structure

const LoaderFallBack = ({isload=false, size}) => {
  return (
    <Container
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',  // Full viewport height
    }}
  >
    {isload ? (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: 300, width: 300 }}
      >
        <Grid item>
          <CircularProgress color="success" size={size}/>
          {/* <img src={loaderGif} alt="Loading..." style={{width:100, height:30}} /> */}
        </Grid>
      </Grid>
    ) : null}
  </Container>
  
  );
};

export default LoaderFallBack;
