import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Add, Delete, Edit } from "@mui/icons-material/";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import {
  api,
  useDeleteAgendaMutation,
  useDeleteSpecializationMutation,
  useGetAgendaListQuery,
  useGetSpecializationQuery,
  useUpdateSpecializationMutation,
  useUploadAgendaDocMutation,
} from "../../services/api";
import { useDispatch } from "react-redux";
import { setSpecializationData } from "../../Redux/GlobalSlice";
import useDrawer from "../../Helpers/customiseHook";
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import UpsertSpecialization from "../UpsertSpecialization";
import moment from "moment";
import { blue, green, orange } from "@mui/material/colors";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, useNavigate } from "react-router-dom";
import ChangeApplicationStatusDialog from "../../Components/Dialog/Dialog";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const AgendaList = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const { state, toggleDrawer } = useDrawer();
  const [showCase, setShowCase] = useState(null);
  // const [document, setDocument] = useState(null);
  const fileRef = useRef(null);
  const [rowId, setRowId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: agendaListData,
    isLoading,
    isError,
    refetch,
  } = useGetAgendaListQuery({
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
  }); // Get Incubation list
  const [uploadAgendaDoc] = useUploadAgendaDocMutation();
  const [deleteProceeding] = useDeleteAgendaMutation();
  const [selectedId, setSelectedId] = useState(null);
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState("");
  const [trackApplication] =
    api.endpoints.trackApplicationStatus.useLazyQuery();
  const role = localStorage.getItem("role").toLowerCase();

  const handleTileClick = (val) => {
    if (val?.techstatus === "selected") {
      toast.warning("Can not perform further actions", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (val?.techstatus !== "selected") {
      setSelectedId(val?._id);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
  };
  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want delete the Proceeding!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteProceeding(id);
        swal("Proceeding has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Proceeding is safe!");
      }
    });
  };

  const columns = useMemo(() => {
    return [
      {
        field: "date",
        headerName: "Date",
        flex: 0.5,
        renderCell: (params) => {
          return (
            <Typography>
              {moment(params?.row?.date).format("DD-MM-YYYY")}
            </Typography>
          );
        },
      },

      {
        field: "application_id",
        headerName: "Applicant",
        flex: 1,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                flexDirection: "column",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)", // Adjust the scale value as needed
                },
              }}
            >
              {Array.isArray(params?.row.application_id) &&
                params?.row.application_id.map((val, index) => {
                  return (
                    <Box
                      className="Proceeding-List"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.background.default,
                        color: (theme) => theme.palette.text.primary,
                        borderWidth: val?.techstatus === "selected" ? 1.5 : 1,
                        borderStyle: "solid",
                        borderColor:
                          val?.techstatus === "selected" ? "#33eb09" : "#ffb5d5",
                      }}
                      key={val?._id}
                      onClick={() => {
                        handleTileClick(val);
                      }}
                    >
                      <Typography variant="body2">
                        <strong>Start-up Name:</strong> {val?.startup_name}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Name:</strong> {val?.user_id?.name}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Email:</strong> {val?.user_id?.email}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Status:</strong> {val?.techstatus}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          );
        },
      },
      {
        field: "actions",
        headerName: "Document",
        flex: 0.5,
        alignItems: "center",
        renderCell: (params) => {
          let id = params?.row?._id;
          return (
            <>
              <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                <FormControl>
                <Button
                    title="Upload Document"
                    variant="contained"
                    size="small"
                    color="info"
                    onClick={() => {
                      document.getElementById(`file-input-${id}`).click();
                    }}
                  >
                  <CloudUploadIcon
                    style={{ cursor: "pointer", color: "#fff" }}                 
                  />
                  <input
                    id={`file-input-${id}`}
                    type="file"
                    accept=".pdf"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      handleUpload(id, event.target.files[0]);
                    }}
                  />
                  </Button>
                </FormControl> &nbsp;
                {params?.row?.document_upload !== null &&
                id === params?.row?._id ? (
                  <Link
                      to={params?.row?.document_upload}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                  <Button
                    title="View Document"
                    variant="contained"
                    size="small"
                  >
                      <VisibilityIcon color="inherit" />
                  </Button>
                    </Link>
                ) : null}
              </Grid> &nbsp;
              {role === "superadmin" || role === "admin" || role === "hod" ? (
                <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                  <Button
                    onClick={() => handleDelete(params.row._id)}
                    title="Delete"
                    variant="contained"
                    size="small"
                    color="error"
                  >
                    {<Delete />}&nbsp; Delete
                  </Button>
                </Grid>
              ) : null}
            </>
          );
        },
      },
      // {
      //   field: "actions",
      //   headerName: "Actions",
      //   type: "actions",
      //   getActions: (params) => [
      //     <GridActionsCellItem
      //       icon={<Edit />}
      //       onClick={(event) => {
      //         // toggleDrawer("right", true)(event);
      //         // setShowCase("edit");
      //         // dispatch(setBlockData(params?.row));
      //       }}
      //       label="Edit"
      //     />,

      //   ],
      // },
    ];
  }, [rowId, document]);
  const handlePageSizeChange = useCallback(
    (data) => {
      setPaginationModel(data);
    },
    [setPaginationModel]
  );
  const handleUpload = async (_id, file) => {
    const formData = new FormData();
    formData.append("document_upload", file);
    const res = await uploadAgendaDoc({ id: _id, parms: formData });
    if (res?.data?.success) {
      toast.success("Document is uploaded successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      refetch(); // Refetch the data to get the latest document URL
    }
  };

  return (
    <Box marginTop={"6em"} padding={4}>
      {/* Inner container */}
      <ToastContainer />
      <Paper className="paper-card">
        {/* Header */}
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h5">Proceeding List</Typography>
          </Grid>
          {/* <Grid className="button-right" item lg={3} md={3} sm={3} xs={3}>
            <Button
              variant="contained"
              onClick={(e) => {
                toggleDrawer("right", true)(e);
                setShowCase("add");
              }}
              startIcon={<Add />}
            >
              <Typography color="white" variant="body1">
                Add new
              </Typography>
            </Button>
          </Grid> */}
        </Grid>

        {/* Table */}
        <Box
          marginTop={2}
          sx={{ width: "100%", height: "500px", overflowX: "auto" }}
        >
          <DataGrid
            columns={columns}
            autoHeight
            getRowId={(row) => row._id}
            rowCount={agendaListData?.total}
            getRowHeight={() => "auto"}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
            pagination
            columnBuffer={columns.length + 1}
            rowBuffer={10}
            paginationModel={paginationModel}
            paginationMode="server"
            onPageSizeChange={(newPageSize) =>
              handlePageSizeChange(newPageSize)
            }
            onPaginationModelChange={(data) => handlePageSizeChange(data)}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              toolbar: GridToolbar,
            }}
            sx={{
              "--DataGrid-overlayHeight": "100%",
              width: "100%",
              marginBottom: "16px",
            }}
            rows={agendaListData?.data || []}
          />
        </Box>
      </Paper>
      <UpsertSpecialization {...{ state, toggleDrawer, showCase }} />
      {open ? (
        <ChangeApplicationStatusDialog
          {...{ open, setOpen, handleClose, selectedId }}
        />
      ) : null}
    </Box>
  );
};

export default AgendaList;
